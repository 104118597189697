import ApplicationController from '../support/application_controller';

export default class extends ApplicationController {
  static targets = [
    'input',
    'radioItem',
    'messageItem',
  ]

  connect() {
    this.moveContent();
    this.toggleSwitch();
    this.setInputRequirments();
  }

  toggleSwitch() {
    this.setInputRequirments();
    this.inputTargets.forEach((input) => {
      const item = this.radioItemAtPosition(input.value);
      if (item != null) {
        const requiredInputs = item.querySelectorAll('input.required, select.required, textarea.required');
        if (input.checked) {
          item.style.display = 'block';
          this.addRequirements(requiredInputs);
        } else {
          item.style.display = 'none';
          this.removeRequirements(requiredInputs);
        }
      }
    });
  }

  setInputRequirments() {
    const found = this.inputTargets.find((input) => input.checked);
    this.inputTargets.forEach((input) => {
      if (found) {
        input.removeAttribute('required');
      } else {
        input.setAttribute('required', 'required');
      }
    });
  }

  radioItemAtPosition(position) {
    return this.radioItemTargets.find((radioItem) => radioItem.dataset.position === position);
  }

  messageItemAtPosition(position) {
    return this.messageItemTargets.find((messageItem) => messageItem.dataset.position === position);
  }

  moveContent() {
    this.inputTargets.forEach((input) => {
      const item = this.radioItemAtPosition(input.value);
      const message = this.messageItemAtPosition(input.value);

      if (item) {
        input.parentNode.appendChild(item);
        item.style.display = 'none';
      }
      if (message) {
        input.parentNode.insertAdjacentElement('afterend', message);
      }
    });
  }

  removeRequirements(requiredInputs) {
    if (!requiredInputs) return null;

    Array.from(requiredInputs).forEach((requiredInput) => {
      requiredInput.removeAttribute('required');
    });
  }

  addRequirements(requiredInputs) {
    if (!requiredInputs) return null;

    Array.from(requiredInputs).forEach((requiredInput) => {
      requiredInput.setAttribute('required', 'required');
    });
  }
}
