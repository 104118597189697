import { Controller } from 'stimulus';

export default class extends Controller {
  defaultHTML;

  static targets = [
    'container',
    'status',
    'statusButton',
    'submitButton',
    'event'
  ];

  connect() {
    this.defaultHTML = this.containerTarget.innerHTML;
    const status = this.getParameterByName('cpd_status');
    if (status === 'submitted' && this.hasStatusTarget && this.hasEventTarget) {
      this.toggleStatus('show', 'Event recorded.', 'The event will appear in Hours Breakdown shortly.');
      this.statusTarget.classList.add('success');
      this.statusButtonTarget.innerText = 'Record another event';
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  toggleStatus(state = 'show', title, body) {
    if (state === 'show') {
      this.containerTarget.classList.add('display-none');
      this.statusTarget.classList.remove('display-none');
    } else {
      this.containerTarget.classList.remove('display-none');
      this.statusTarget.classList.add('display-none');
    }
    this.statusTarget.querySelector('h5').innerText = title;
    this.statusTarget.querySelector('.status__body').innerText = body;
  }

  success(event) {
    window.location.search += '&cpd_status=submitted';
  }

  error(event) {
    this.toggleStatus('show', event.detail[0].title, event.detail[0].body);
    this.statusTarget.classList.remove('success');
    this.statusButtonTarget.innerText = 'Back to search';
  }

  searchSuccess(event) {
    let childNodes = event.currentTarget.parentElement.parentElement.parentElement.childNodes;

    for(let i = 0; childNodes[i]; i++) {
        while(childNodes[i] && childNodes[i].className == 'margin-bottom-2')
          document.querySelectorAll('.cpd-record-event__inner')[0].removeChild(childNodes[i]);
    }
    if (event.detail[0]) {
      this.containerTarget.innerHTML += (event.detail[0].content);
    }
  }

  resetForm() {
    const inputs = this.containerTarget.querySelectorAll('input[type="text"], select');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].parentElement.parentElement.classList.remove('focused');
      inputs[i].value = '';
    }
    this.submitButtonTarget.classList.add('disabled');
    this.submitButtonTarget.addAttribute('disabled', 'disabled');
  }

  setDefaultHTML() {
    this.toggleStatus('hide');
    this.containerTarget.innerHTML = this.defaultHTML;
  }
}
