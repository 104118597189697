export default class PaymentsHelper {
  static examDate = (item) => {
    const examMatch = item.name.match(/Exam date: ([\w-]*)/);
    let examDate = '';

    if (examMatch !== null) examDate = `Exam date: ${examMatch[1]}`;

    return examDate;
  }

  static examVenue = (item) => {
    const venueMatch = item.attributes.venue.match(/Exam Venue: (?<exam_venue>\w*)<br>/);
    let examVenue = '';

    if (venueMatch !== null) {
      examVenue = ` - Exam Location: ${venueMatch.groups.exam_venue}`;
    }
    return examVenue;
  }

  static findToggleIndex = (targets, index) => targets.find((toggle) => toggle.dataset.index === index)

  static employerUserChecked = (currentEl, disclosureEl, employerEl) => currentEl === disclosureEl && disclosureEl.checked && employerEl.checked
}
