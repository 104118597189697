import { Controller } from 'stimulus';

const BUTTON_ACTIVE_CLASS = 'cpd-show-page__nav-links-button--active';

export default class extends Controller {
  static targets = ['button', 'content']

  setNavFocus(content) {
    for (let button of this.buttonTargets) {
      if (button.dataset.content === content) {
        button.classList.add(BUTTON_ACTIVE_CLASS)
      } else {
        button.classList.remove(BUTTON_ACTIVE_CLASS)
      }
    }
  }

  scrollTo({ currentTarget }) {
    let yOffset = -70;
    let content = this._findContent(currentTarget.dataset.content);
    let y = content.getBoundingClientRect().top + window.pageYOffset + yOffset;
    this.setNavFocus(currentTarget.dataset.content);
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  _findContent(name) {
    for (let content of this.contentTargets) {
      if (content.dataset.content === name) return content;
    }
  }
}