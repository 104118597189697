import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'title',
    'card',
  ]

  static values = {
    search: Boolean,
  }

  hide(searchWords, searchFilters = []) {
    const cardTittle = this.titleTarget.innerText.toLowerCase();
    const searchedWords = searchWords.filter((word) => cardTittle.includes(word));
    const searchedFilters = searchFilters.filter((filter) => this.tags.includes(filter));
    if ((searchedWords.length >= 1 || searchWords.length === 0) && (searchedFilters.length >= 1 || searchFilters.length === 0)) {
      this.search = true;
    } else {
      this.search = false;
    }
  }

  get title() {
    return this.titleTarget.innerText;
  }

  get visible() {
    return !(this.cardTarget.parentNode.classList.contains('display-none'));
  }

  get search() {
    return this.searchValue;
  }

  set search(value) {
    this.searchValue = value;
  }

  set visible(value) {
    if (value) {
      this.cardTarget.parentNode.classList.remove('display-none');
    } else {
      this.cardTarget.parentNode.classList.add('display-none');
    }
  }

  get tags() {
    return this.cardTarget.dataset.tags;
  }
}
