import { Controller } from "stimulus";

export default class extends Controller {
  static classes = ['active']

  static targets = ['frame']

  static values = {
    url: String,
  }

  static outlets = ['search-form']

  connect() {
    this.activeLink = null;
    this.deselectedUrl = null;
  }

  clearActiveLinks() {
    if (this.activeLink) {
      this.activeLink.classList.remove(this.activeClass);
    }
  }

  unsetActiveLink() {
    this.activeLink.classList.remove(this.activeClass);
    this.activeLink.setAttribute('href', this.deselectedUrl);
    this.activeLink = null;
  }

  setActiveLink(event) {
    this.activeLink = event.target.closest('a');
    this.activeLink.classList.add(this.activeClass);
    this.deselectedUrl = this.activeLink.getAttribute('href');
    this.activeLink.setAttribute('href', this.urlValue);
  }

  handleTurboClick(event) {
    this.clearActiveLinks();
    this.clearInput();
    if ((this.activeLink) && (this.activeLink === event.target.closest('a'))) {
      this.unsetActiveLink();
    } else {
      this.setActiveLink(event);
    }
  }

  clearInput() {
    if (this.hasSearchFormOutlet) {
      this.searchFormOutlet.clearInput();
    }
  }
}
