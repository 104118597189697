import { Controller } from 'stimulus';
import Headroom from '../support/headroom.js';

export default class extends Controller {
  static targets = [
    'header',
    'navBar',
  ];

  initialize() {
    if(window.location.pathname !== '/'){
      this.element.classList.add('header--is-normal');
    }

  }

  connect() {
    this.onWindowScroll = this.onWindowScroll.bind(this);
    this.navBarHeight = this.navBarTarget.offsetHeight;

    window.addEventListener('scroll', this.onWindowScroll);

    const headroom = new Headroom(this.headerTarget);
    headroom.init();
    this.purchaseJourneyPointerEvents();
  }

  purchaseJourneyPointerEvents() {
    let pageURL = window.location.href;
    if(pageURL.includes('registration') || pageURL.includes('enrolments') || pageURL.includes('applications/new'))
      document.querySelector('.header').style.pointerEvents = 'none';
  }

  disconnect() {
    window.removeEventListener('scroll', this.onWindowScroll);
  }

  onWindowScroll() {
    if (window.pageYOffset > this.navBarHeight) {
      this.element.classList.add('header--is-scrolled');
    } else {
      this.element.classList.remove('header--is-scrolled');
    }
  }
}
