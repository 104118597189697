import ApplicationController from '../support/application_controller';

export default class extends ApplicationController {
  static targets = [
    'attendanceForm',
    'toggle',
    'formInput',
    'memberNumber',
    'attendantTemplateName',
    'attendantTemplateMember',
    'attendants',
    'next',
    'error',
    'attendantButton',
  ];

  get spotsAvailable() {
    return this.data.get('spots-available');
  }

  get onePerOrder() {
    return this.data.get('one-per-order');
  }

  get membersOnly() {
    return this.data.get('members-only') === 'true';
  }

  connect = () => {
    this.isMembershipRequired();
    this.currentCapacity = parseInt(this.spotsAvailable, 10);
    if (this.onePerOrder !== 'true' && this.hasAttendantButtonTarget) this.checkCapacity();
  }

  addToAttendance = () => {
    this.attendanceFormTarget.classList.remove('display-none');
    this.nextTarget.classList.add('disabled');
    this.attendantButtonTarget.classList.add('display-none');
    this.isMembershipRequired();
  }

  isMembershipRequired = () => {
    if ((this.hasToggleTarget && this.toggleTarget.checked) || this.membersOnly) {
      this.attendantIsMember(this.formInputTargets);
    } else {
      this.attendantIsNotMember(this.formInputTargets);
    }
  }

  attendantIsMember = (inputs) => {
    inputs.forEach((input) => {
      this.getParentComponent(input, 2).classList.add('display-none');
    });
    this.getParentComponent(this.memberNumberTarget, 2).classList.remove('display-none');
    this.attachRequiredAttr(this.memberNumberTarget);
    this.clearNonMemberInputs();
  }

  attendantIsNotMember = (inputs) => {
    inputs.forEach((input) => {
      this.getParentComponent(input, 2).classList.remove('display-none');
      this.attachRequiredAttr(input);
    });
    if (this.hasMemberNumberTarget) {
      this.getParentComponent(this.memberNumberTarget, 2).classList.add('display-none');
      this.clearMemberInputs();
    }
  }

  clearNonMemberInputs = () => {
    this.formInputTargets.forEach((input) => {
      input.value = '';
      this.resetInputState(input);
    });
  }

  clearMemberInputs = () => {
    if (!this.hasMemberNumberTarget) return;

    this.memberNumberTarget.value = '';
    this.resetInputState(this.memberNumberTarget);
  }

  resetInputState = (elements) => {
    this.getParentComponent(elements, 2).classList.remove('focused', 'valid', 'has-message', 'error');
    elements.removeAttribute('required');
    elements.setAttribute('aria-required', 'false');
  }

  attachRequiredAttr = (elements) => {
    elements.setAttribute('required', 'required');
    elements.setAttribute('aria-required', 'true');
  }

  addAttendant = (event) => {
    const { options } = event.detail[0];
    const { item_id } = event.detail[0];
    if (options.member_number) {
      this.attendantsTarget.appendChild(this.newAttendantMember(options.member_number, item_id));
    } else {
      this.attendantsTarget.appendChild(this.newAttendantName(options.first_name, options.surname, item_id));
    }
    this.currentCapacity -= 1;
    this.cancelAttendant();
  }

  checkCapacity = () => {
    if (this.currentCapacity <= 0) {
      this.hideAddAttendant();
    } else {
      this.showAddAttendant();
    }
  }

  hideAddAttendant = () => {
    this.attendantButtonTarget.classList.add('display-none');
  }

  showAddAttendant = () => {
    this.attendantButtonTarget.classList.remove('display-none');
  }

  cancelAttendant = () => {
    this.attendanceFormTarget.classList.add('display-none');
    this.clearNonMemberInputs();
    this.clearMemberInputs();
    this.nextTarget.classList.remove('disabled');
    this.errorTarget.classList.add('display-none');
    this.attendantButtonTarget.classList.remove('display-none');
    this.checkCapacity();
  }

  removeAttendant = (event) => {
    const item = this.attendantsTarget.querySelector(`#item_id_${event.detail[0].item_id}`);
    item.parentNode.removeChild(item);
    this.nextTarget.classList.remove('disabled');
    this.currentCapacity += 1;
    this.checkCapacity();
  }

  attError = (event) => {
    this.errorTarget.innerText = event.detail[0].errors[0].text;
    this.errorTarget.classList.remove('display-none');
  }

  loading = () => {
    this.nextTarget.classList.add('disabled');
  }

  done = () => {
    this.nextTarget.classList.remove('disabled');
  }

  newAttendantName = (firstName, lastName, item_id) => {
    const newAttendantText = this.attendantTemplateNameTarget.innerHTML
      .replace(/TEMPLATE_FIRST_NAME/g, firstName)
      .replace(/TEMPLATE_LAST_NAME/g, lastName)
      .replace(/TEMPLATE_ITEM_ID/g, item_id);

    const newAttendantElement = document.createElement('div');
    newAttendantElement.innerHTML = newAttendantText;
    return newAttendantElement;
  }

  newAttendantMember = (memberNumber, item_id) => {
    const newAttendantText = this.attendantTemplateMemberTarget.innerHTML
      .replace(/TEMPLATE_MEMBER_NUMBER/g, memberNumber)
      .replace(/TEMPLATE_ITEM_ID/g, item_id);

    const newAttendantElement = document.createElement('div');
    newAttendantElement.innerHTML = newAttendantText;
    return newAttendantElement;
  }

  gaDetailsTab(){
    dataLayer.push({
      'event': 'enrollments_funnel',
      'event_category': 'Enrollments',
      'event_action': 'Enrolled: ' + this.nextTarget.dataset.name,
      'event_label': 'Payments Tab',
      'programme_name': this.nextTarget.dataset.name,
      'enrollments_step': 'Payments Tab'
    });
  }

  gaEventsBeginCheckout(){
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      'event': 'begin_checkout',
      'event_category': 'Enhanced Ecommerce',
      'event_action': 'Checkout',
      'event_label': 'Step 1: Personal',
      'programme_name': this.nextTarget.dataset.name,
      'ecommerce': {
          'currencyCode': 'EUR',
          'checkout': {
            'actionField': { 'step': 1, 'option': 'Payment' },
            'products': [{
              'name': this.nextTarget.dataset.pname,
              'id': this.nextTarget.dataset.programid,
              'price': this.nextTarget.dataset.price,
              'brand': 'IOB',
              'category': this.nextTarget.dataset.category,
              'variant': this.nextTarget.dataset.variant,
              'quantity': this.nextTarget.dataset.quantity,
            }]
          }
        }
    });
  }
}
