import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'empty',
    'searchForm',
    'allFilter',
    'filter',
  ];

  static outlets = [
    'programmes-grid',
    'programmes-slider',
    'search-form',
  ]

  static classes = ['emptyText'];

  static values = {
    pageLoad: Boolean,
  }

  filter(event) {
    event.preventDefault();
    if (this.allFilterTargets.includes(event.srcElement)) {
      this.filterTargets.forEach((filter) => {
        filter.classList.remove('is-active');
      });
      this.allFilterTargets.forEach((filter) => {
        filter.classList.add('is-active');
      });
    }
    else {
      this.allFilterTargets.forEach((filter) => {
        filter.classList.remove('is-active');
      });
      if (event.srcElement.classList.contains('is-active')) {
        event.srcElement.classList.remove('is-active');
        if (event.srcElement.dataset.mobile === 'true') {
          this.desktopTags.forEach((filter) => {
            if (filter.dataset.filter === event.srcElement.dataset.filter) {
              filter.classList.remove('is-active');
            }
          });
        } else {
          this.mobileTags.forEach((filter) => {
            if (filter.dataset.filter === event.srcElement.dataset.filter) {
              filter.classList.remove('is-active');
            }
          });
        }
      } else {
        event.srcElement.classList.add('is-active');
        if (event.srcElement.dataset.mobile === 'true') {
          this.desktopTags.forEach((filter) => {
            if (filter.dataset.filter === event.srcElement.dataset.filter) {
              filter.classList.add('is-active');
            }
          });
        } else {
          this.mobileTags.forEach((filter) => {
            if (filter.dataset.filter === event.srcElement.dataset.filter) {
              filter.classList.add('is-active');
            }
          });
        }
      }
    }


    this.searchFormTarget.requestSubmit();
  }

  search(event) {
    event.detail.formSubmission.stop();
    this.syncFormInputs();
    this.resetNoResults();
    const searchValue = document.querySelectorAll('.search-form__input #search_query')[0].value;
    this.searchGrids(searchValue, this.activeTagNames);
    this.searchSlider(searchValue, this.activeTagNames);
    this.resetFormInputs();
  }

  connect() {
    this.resetNoResults();
    this.searchGrids(this.urlSearch, this.urlTags);
    this.searchSlider(this.urlSearch, this.urlTags);
  }

  searchSlider(searchValue, tags = []) {
    const searchWords = searchValue.toLowerCase().trim().split(' ').filter((word) => word.length > 0);
    let resultLength = 0;
    if (this.hasProgrammesSliderOutlet) {
      this.programmesSliderOutlets.forEach((slider) => {
        let sliderSize = slider.searchCards(searchWords, tags)
        resultLength += sliderSize;
      })
    }

    if (resultLength === 0) {
      this.programmesSliderOutlets.forEach((slider) => {
        slider.showAll();
      });
    }
  }

  searchGrids(searchValue = '', tags = []) {
    const searchWords = searchValue.toLowerCase().trim().split(' ').filter((word) => word.length > 0);
    this.URL = {
      'searchWords': searchWords,
      'tags': tags,
    };
    let resultLength = 0;
    if (this.hasProgrammesGridOutlet) {
      this.programmesGridOutlets.forEach((grid) => {
        let gridSize = grid.searchCards(searchWords, tags).length;
        resultLength += gridSize;
      });
    }

    if (resultLength === 0 && searchWords.length > 0) {
      this.noResults(searchValue);
      this.programmesGridOutlets.forEach((grid) => {
        grid.showAll();
      });
    }
  }

  noResults(searchValue) {
    this.emptyTarget.classList.remove('display-none');
    const message = this.emptyTarget.querySelector(`.${this.emptyTextClass}`).innerText.replace('PLACEHOLDER', `"${searchValue}"`);
    this.emptyTarget.querySelector(`.${this.emptyTextClass}`).innerText = message;
  }

  resetNoResults() {
    this.emptyTarget.querySelector(`.${this.emptyTextClass}`).innerText = 'No results found for PLACEHOLDER';
    this.emptyTarget.classList.add('display-none');
  }

  resetFormInputs() {
    document.querySelectorAll('.search-form__input #search_query')[0].value = '';
  }

  syncFormInputs() {
    const searchInputs = document.querySelectorAll('.search-form__input #search_query');
    const firstInput = searchInputs[0];
    if (firstInput.value === '') {
      firstInput.value = document.querySelectorAll('.search-form__input #search_query')[1].value;
    } else {
      document.searchInputs[1].value = firstInput.value;
    }
  }

  setSearchForm(searchWords) {
    document.querySelectorAll('.search-form__input #search_query').forEach((input) => {
      input.value = searchWords.join(' ');
    });
  }

  setTags(tags) {
    this.mobileTags.forEach((filter) => {
      if (tags.includes(filter.dataset.filter)) {
        filter.classList.add('is-active');
      } else {
        filter.classList.remove('is-active');
      }
    })

    this.desktopTags.forEach((filter) => {
      if (tags.includes(filter.dataset.filter)) {
        filter.classList.add('is-active');
      } else {
        filter.classList.remove('is-active');
      }
    })

    if (tags.length > 0) {
      this.allFilterTargets.forEach((filter) => {
        filter.classList.remove('is-active');
      });
    }
    this.pageLoadValue = false
  }

  set URL(searchParams = {}) {
    const searchWords = searchParams['searchWords']
    const tags = searchParams['tags']

    const searchWordsParams = (searchWords.length > 0) ? `search_words=[${searchWords.map((f) => `${f}`).join('; ')}]&` : '';
    const tagsParams = (tags.length > 0) ? `tags=[${tags.map((f) => `${f}`).join('; ')}]` : '';
    const state = { searchWords: searchWords, tags: tags, turbo: window.history.state.turbo };
    if (this.pageLoadValue === true) {
      this.setSearchForm(searchWords);
      this.setTags(tags);
    }
    window.history.pushState(state, '', encodeURI(`${window.location.pathname}?${searchWordsParams}${tagsParams}`));
  }

  get activeTagNames() {
    return this.mobileTags.filter((filter) => filter.classList.contains('is-active')).map((filter) => filter.dataset.filter);
  }

  get mobileTags() {
    return this.filterTargets.filter((filter) => filter.dataset.mobile === 'true');
  }

  get desktopTags() {
    return this.filterTargets.filter((filter) => filter.dataset.mobile === 'false');
  }

  get params() {
    return new URLSearchParams(window.location.search)
  }

  get urlSearch() {
    return this.params.get('search_words') === null ? '' : this.params.get('search_words').replace(/[\[\]\;]/g, '');
  }

  get urlTags() {
    const tagParams = this.params.get('tags')
    return tagParams === null ? [] : tagParams.replace(/[\[\]]/g, '').split('; ');
  }
}
