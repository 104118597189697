import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'script'
  ];

  connect() {
    for (let script of this.scriptTargets) {
      let json = JSON.parse(script.text);
      dataLayer.push(json);
      script.remove();
    }
    this.element.remove();
  }
}
