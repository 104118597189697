import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'searchInput',
    'searchForm',
  ]

  search() {
    this.searchFormTarget.requestSubmit();
  }

  clearInput() {
    this.searchInputTarget.value = '';
  }
}
