import { Controller } from 'stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = [
    'container',
    'card'
  ];

  connect() {
    if(this.cardTargets.length <= 1) return;

    this.card = new Swiper(this.containerTarget, {
      speed: 500,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }
}
