import { Controller } from 'stimulus';

export function showLoader() {
  document.getElementById("loader-anim").style.display = "block";
}

export function hideLoader() {
  document.getElementById("loader-anim").style.display = "none";
}

function disableButton(button) {
  let enableButton = () => {
    button.classList.remove('button--disabled');
    document.removeEventListener('turbo:before-cache', enableButton);
  }
  button.classList.add('button--disabled');
  document.addEventListener('turbo:before-cache', enableButton);
}

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:before-cache', hideLoader);
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', hideLoader);
  }

  show(event) {
    showLoader();
    if (event && event.target.classList.contains('button')) {
      disableButton(event.target);
    }
    if (event && event.target.tagName.toUpperCase() === 'A') {
      event.preventDefault();
      setTimeout(() => window.location.href = event.target.href, 100);
    }
  }

  hide() {
    hideLoader();
  }
}
