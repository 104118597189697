import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'allButton',
    'button',
    'item',
    'itemsContainer',
    'parent',
    'child',
    'empty',
  ];

  get filters() {
    return Array.from(new Set(this.buttonTargets
      .filter((button) => button.classList.contains('is-active'))
      .map((button) => button.dataset.tag)));
  }

  set filters(value) {
    this.buttonTargets.forEach((button) => {
      if (value.includes(button.dataset.tag)) {
        button.classList.add('is-active');
      } else {
        button.classList.remove('is-active');
      }
    });
    this.updateAllButton(value);
    this.filterItems();
    this.setURL();
  }

  filter(event) {
    const { tag } = event.currentTarget.dataset;
    // const { filters } = this;
    // if (filters.includes(tag)) {
    //   this.filters = this.filters.filter((f) => f !== tag);
    // } else {
      this.filters = [tag];
    // }
    event.preventDefault();
  }

  get ajaxUrl() {
    return this.data.element.dataset.ajaxUrl;
  }

  filterAjax(event) {
    this.filter(event);
    this.sendAjax();
  }

  sendAjax() {
    const requestUrl = `${this.ajaxUrl}?${this.filters.map((f) => `tags[]=${f}`).join('&')}`;
    const oReq = new XMLHttpRequest();
    const controller = this;
    oReq.addEventListener('load', (event) => {
      controller.itemsContainerTarget.innerHTML = JSON.parse(event.currentTarget.response).content;
    });
    oReq.open('GET', requestUrl);
    oReq.send();
  }

  allFilterAjax(event) {
    this.allFilter(event);
    this.sendAjax();
  }

  renderItems({ detail }) {
    this.itemsContainerTarget.innerHTML = detail[0].content;
    this.element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  allFilter(event) {
    this.filters = [];
    event.preventDefault();
  }

  connect() {
    this.updateAllButton();
    this.filterItems();
  }

  filterByType(itemTags, filters) {
    return this.data.get('type') === 'exclusive'
      ? this.containsAllTags(itemTags, filters)
      : itemTags.some((t) => this.filters.includes(t));
  }

  filterItems() {
    const { filters } = this;
    this.itemTargets.forEach((item) => {
      const itemTags = item.dataset.tags.split(';').map((t) => t.trim());
      if (filters.length === 0 || this.filterByType(itemTags, filters)) {
        item.classList.remove('display-none');
      } else {
        item.classList.add('display-none');
      }
    });
    this.handleHiddenItems(this.parentTargets);
  }

  handleHiddenItems(parentTargets) {
    if (parentTargets.length) {
      parentTargets.forEach((parentTarget) => {
        const hiddenChildrenLength = parentTarget.querySelectorAll('[data-filters-target*="child"].display-none').length;
        const childrenLength = parentTarget.querySelectorAll('[data-filters-target*="child"]').length;
        if (hiddenChildrenLength === childrenLength) {
          parentTarget.classList.add('display-none');
        } else {
          parentTarget.classList.remove('display-none');
        }
      });
      if (this.parentTargets.every((parentTarget) => parentTarget.classList.contains('display-none')) && this.hasEmptyTarget) {
        this.emptyTarget.classList.remove('display-none');
      } else {
        this.emptyTarget.classList.add('display-none');
      }
    }
  }

  containsAllTags(itemTags, filterTags) {
    return filterTags.every((filterTag) => itemTags.includes(filterTag));
  }

  setURL() {
    const { filters } = this;
    if (filters.length === 0) {
      const state = { turbo: window.history.state.turbo };
      window.history.pushState(state, '', `${window.location.pathname}`);
    } else {
      const state = { tags: filters, turbo: window.history.state.turbo };
      window.history.pushState(state, '', `${window.location.pathname}?${this.filters.map((f) => `tags[]=${f}`).join('&')}`);
    }
  }

  updateAllButton(filters = this.filters) {
    if (filters.length === 0) {
      this.allButtonTargets.forEach((button) => button.classList.add('is-active'));
    } else {
      this.allButtonTargets.forEach((button) => button.classList.remove('is-active'));
    }
  }
}
