import { Controller } from 'stimulus';
import toastr from 'toastr';

export default class extends Controller {
  static targets = ['flash']

  connect() {
    for (let flash of this.flashTargets) {
      toastr[flash.dataset.type](flash.dataset.message)
    }
  }
}