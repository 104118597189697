const DEFAULT_OPTIONS = {
  subtree: true,
  attributes: true,
  childList: true,
  characterData: false,
  attributeFilter: ['href']
};

export default class LinkObserver {
  constructor(check, handler, options = DEFAULT_OPTIONS) {
    this.check = check;
    this.handler = handler;
    this.options = options;

    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        switch (mutation.type) {
          case 'attributes':
            this.handleHrefChange(mutation.target);
          break;
          case 'childList':
            this.handleChildListChange(mutation.addedNodes);
          break;
        }
      });
    });
  }

  disconnect() {
    this.observer.disconnect();
  }

  observe(element) {
    for (let anchor of element.querySelectorAll('a')) {
      this.handleNodeAdded(anchor);
    }

    this.observer.observe(element, this.options);
  }

  handleHrefChange(target) {
    if (this.check(target)) {
      this.addHandler(target);
    } else {
      this.removeHandler(target);
    }
  }

  handleChildListChange(addedNodes) {
    for (let target of addedNodes) {
      if (target.nodeName === 'A') {
        this.handleNodeAdded(target);
      } else if (target.nodeName !== '#text') {
        for (let anchor of target.querySelectorAll('a')) {
          this.handleNodeAdded(anchor);
        }
      }
    }
  }

  handleNodeAdded(target) {
    if (target.getAttribute('href') === '#noop') {
      this.addHandler(target);
    }
  }

  addHandler(target) {
    target.addEventListener('click', this.handler);
  }
  
  removeHandler(target) {
    target.removeEventListener('click', this.handler);
  }
}