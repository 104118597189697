import { Controller } from 'stimulus';
import LinkObserver from '../helper-classes/link_observer';

export default class extends Controller {
  connect() {
    this.observer = new LinkObserver(
      target => target.getAttribute('href') === '#noop',
      event => event.preventDefault()
    );
    this.observer.observe(this.element);
  }

  disconnect() {
    this.observer.disconnect();
  }
}
