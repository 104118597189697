/* eslint-disable import/prefer-default-export */
export class InputValidator {
  static fileFormats = [
    'application/doc',
    'application/excel',
    'application/ms-doc',
    'application/mspowerpoint',
    'application/msword',
    'application/pdf',
    'application/powerpoint',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-outlook',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/x-excel',
    'application/x-msexcel',
    'application/x-mspowerpoint',
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/tiff',
    'image/x-tiff',
    'text/plain',
    'text/rtf'
  ]

  static required(element) {
    return element.getAttribute('required');
  }

  static pattern(element) {
    return element.getAttribute('pattern');
  }

  static message(element) {
    return element.getAttribute('message') || 'Invalid input';
  }

  static setInputErrorState(element, message) {
    const elementContainer = element.parentElement.parentElement;
    element.setCustomValidity(message);
    if (message) elementContainer.querySelector('.input-message').innerText = message;
    elementContainer.classList.add('has-message', 'error');
    elementContainer.classList.remove('valid');
    return false;
  }

  static removeInputErrorState(element) {
    const elementContainer = element.parentElement.parentElement;
    element.setCustomValidity('');
    if (elementContainer.querySelector('.input-message')) {
      elementContainer.querySelector('.input-message').innerText = '';
    }
    elementContainer.classList.remove('has-message', 'error');
    elementContainer.classList.add('valid');
    return true;
  }

  static validatePattern(element) {
    if (this.pattern(element) && (element.value || this.required(element))) {
      return this.matchPatternValidity(element)
        ? this.removeInputErrorState(element)
        : this.setInputErrorState(element, this.message(element));
    }
    return true;
  }

  static matchPatternValidity(element) {
    return (element.value.match(this.pattern(element)) && element.checkValidity());
  }

  static validateRequired(element) {
    if (this.required(element)) {
      return element.value
        ? this.removeInputErrorState(element)
        : this.setInputErrorState(element, 'This field is required');
    }
    this.removeInputErrorState(element);
    return true;
  }

  static acceptedFileTypes(element) {
    const accepted_types = element.dataset.fileFields;
    if (accepted_types) {
      return accepted_types.split(',');
    }
    return this.fileFormats;
  }

  static validateFiles(element) {
    const controller = this;
    const files = element.files;
    const totalFileSize = Array.from(files).reduce(
      (total, file) => total + file.size, 0,
    );
    if (totalFileSize > 15000000) {
      return this.setInputErrorState(element, 'Uploaded files exceed 15MB');
    }
    Array.from(files).forEach((file) => {
      if (!controller.acceptedFileTypes(element).includes(file.type) && file.name.match(/.([a-zA-Z0-9]*)$/g)[0] != '.msg') {
        return controller.setInputErrorState(
          element,
          `Cannot accept files of type ${file.name.match(/.([a-zA-Z0-9]*)$/g)}`,
        );
      }
      if (file.name.length > 60) {
        return controller.setInputErrorState(element, 'File name length exceeds 60 characters');
      }
      if (!file.name.match(/^[a-zA-Z0-9\s._-]+$/g)) {
        const charString = file.name.match(/[^a-zA-Z0-9\s._-]+/g).filter((x, i, a) => a.indexOf(x) === i).toString().replace(/,/g, ', ');
        return controller.setInputErrorState(element, `File name cannot contain the characters ${charString}`);
      }
    });
    return true;
  }
}
