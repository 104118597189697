import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['data']

  addToCart() {
    if (!this.hasDataTarget) return;

    dataLayer.push({ ecommerce: null });
    dataLayer.push(JSON.parse(this.dataTarget.text));
  }
}