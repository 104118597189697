import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'assessmentContainer',
    'uploadStatus',
    'examRegulationsTimestamp',
    'CAChecklistTimestamp',
    'examRegulations',
    'CAChecklist',
  ];

  eventTimestamp = (target) => {
    const today = new Date();
    return target.value ? today.toUTCString() : '';
  }

  toggleExaminationRegulationsTimestamp() {
    this.examRegulationsTimestampTarget.value = this.eventTimestamp(this.examRegulationsTarget);
  }

  toggleCAChecklistTimestamp() {
    this.CAChecklistTimestampTarget.value = this.eventTimestamp(this.CAChecklistTarget);
  }

  error() {
    this.assessmentContainerTarget.setAttribute('data-hidden', true);
    this.uploadStatusTarget.removeAttribute('data-hidden');
    this.uploadStatusTarget.classList.add('error');
    this.assessmentContainerTarget.setAttribute('data-hidden', true);
    this.uploadStatusTarget.querySelector('.description').innerText = 'Assessment Upload Failed, please try again or contact support.';
  }

  resetFileInputs() {
    const inputs = this.assessmentContainerTarget.querySelectorAll('input[type="file"]');
    for (let i = 0; i < inputs.length; i += 1) {
      inputs[i].value = '';
      const inputSelector = this.assessmentContainerTarget.querySelector('.input-selector');
      inputSelector.classList.remove('display-none');
      const fileList = this.assessmentContainerTarget.querySelector('.file-list');
      fileList.innerHTML = '';
    }
  }

  resetSelectInputs() {
    const inputs = this.assessmentContainerTarget.querySelectorAll('select');
    for (let i = 0; i < inputs.length; i += 1) {
      inputs[i].value = '';
      inputs[i].parentElement.parentElement.classList.remove('focused');
      inputs[i].parentElement.parentElement.classList.remove('valid');
    }
  }

  resetCheckboxInputs() {
    const inputs = this.assessmentContainerTarget.querySelectorAll('input[type="checkbox"]');
    for (let i = 0; i < inputs.length; i += 1) {
      inputs[i].checked = false;
    }
  }

  resetForm() {
    this.resetFileInputs();
    this.resetSelectInputs();
    this.resetCheckboxInputs();
    this.assessmentContainerTarget.querySelector('input[type="submit"]').classList.add('disabled');
    this.assessmentContainerTarget.querySelector('input[type="submit"]').setAttribute('disabled', 'disabled');
  }

  success() {
    this.assessmentContainerTarget.setAttribute('hidden', true);
    this.uploadStatusTarget.removeAttribute('data-hidden');
    this.uploadStatusTarget.classList.remove('error');
    this.assessmentContainerTarget.setAttribute('data-hidden', true);
    this.uploadStatusTarget.querySelector('.description').innerText = 'Assessment uploaded with success.';
  }
}
