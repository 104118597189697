import ApplicationController from '../support/application_controller';

export default class extends ApplicationController {
  static targets = [
    'sourceValue',
    'element',
  ]

  connect() {
    this.setValidation();
  }

  get rules() {
    return JSON.parse(this.data.get('rules'));
  }

  set activeRuleSet({ required = false, length = 0, pattern }) {
    this.inputRequired = required;
    this.inputLength = length;
    if (length.min > 0) pattern['message'] += ` and should be ${length.min} characters long`;
    if (pattern) this.inputPattern = pattern;
  }

  set inputRequired(required) {
    this.removeAttribute('required');
    required && this.addAttribute('required', 'required');
  }

  set inputLength({ min, max }) {
    this.removeAttribute('maxlength');
    this.removeAttribute('minlength');
    if (max) {
      this.addAttribute('maxlength', max);
    }
    if (min) {
      this.addAttribute('minlength', min);
    }
  }

  set inputPattern({ pattern, message }) {
    this.removeAttribute('pattern');
    this.removeAttribute('message');
    this.addAttribute('pattern', pattern);
    this.addAttribute('message', message);
  }

  unsetAllRules() {
    this.removeAttribute('required');
    this.removeAttribute('message');
    this.removeAttribute('pattern');
    this.removeAttribute('maxlength');
    this.removeAttribute('minlength');
  }

  removeAttribute(attribute) {
    this.elementTarget.removeAttribute(attribute);
  }

  addAttribute(attrName, attrValue) {
    this.elementTarget.setAttribute(attrName, attrValue);
  }

  setValidation() {
    this.unsetAllRules();
    const rule = this.findValidationRule(this.sourceValueTarget.value);
    if (rule) this.activeRuleSet = rule;
    this.changeEvent(this.elementTarget);
  }

  findValidationRule(ruleName) {
    return this.rules.find(({ name }) => name === ruleName);
  }
}
