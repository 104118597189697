import Rails      from '@rails/ujs';
import "@hotwired/turbo-rails"

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@stimulus/polyfills';
import 'foundation-sites';
import 'application';
import 'controllers';
import 'jquery';

Rails.start();

navigator.serviceWorker.getRegistrations().then(function(registrations) {
  for (let registration of registrations) {
    registration.unregister()
  }
});
