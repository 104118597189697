export class FileInputHelper {
  static limitFileNameLength(fileName) {
    if (fileName.length >= 30) {
      return `${fileName.substring(0, 27)}...`;
    }
    return fileName;
  }

  static addRemoveFileButton(element, input) {
    const helper = this;
    if (!input.getAttribute('multiple')) {
      const removeButton = document.createElement('div');
      removeButton.innerText = 'Remove';
      removeButton.classList.add('button__text-only');
      removeButton.classList.add('button');
      removeButton.classList.add('blue');
      removeButton.addEventListener('click', (event) => {
        input.value = null;
        helper.toggleAddFileButton(element.parentElement.parentElement, true);
        element.remove();
        if ('createEvent' in document) {
          const evt = document.createEvent('HTMLEvents');
          evt.initEvent('change', false, true);
          input.dispatchEvent(evt);
        } else input.fireEvent('onchange');
      });
      element.appendChild(removeButton);
    }
  }

  static addInputChangeDetection(element) {
    let observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if (mutation.type === "attributes" && !mutation.target.parentElement.parentElement.querySelector('.input-message').innerText) {
          let fileName = mutation.target.dataset['before'].split(' ') || mutation.target.dataset['before'];
          if(mutation.target.parentElement.parentElement.querySelector('.file-container')) {
            let currentFiles = mutation.target.parentElement.parentElement.querySelectorAll('.file-container');
            for(let i = 0; i < currentFiles.length; i++) {
              currentFiles[i].remove();
            }
          }
          for (let i = 0; i < fileName.length; i++) {
            let fileContainer = document.createElement('div');
            fileContainer.innerText = fileName[i];
            fileContainer.classList.add('file-container');
            fileContainer.setAttribute('data-index', i.toString());
            mutation.target.childNodes[0].parentNode.insertBefore(fileContainer, mutation.target.querySelector('div'));
          }
        }
      });
    });
    
    observer.observe(element.querySelector('label'), {
      attributes: true
    });
  }

  static toggleAddFileButton(element, visible) {
    const selector = element.parentElement.querySelector('.input-selector');
    if (this.canToggle(element, visible, selector)) {
      selector.classList.add(visible ? 'display-block' : 'display-none');
      selector.classList.remove(visible ? 'display-none' : 'display-block');
    }
  }

  static canToggle(element, visible, selector) {
    const hasMultiple = !!element.querySelector('input').getAttribute('multiple');
    return selector && !hasMultiple;
  }

  static addFileButton(element) {
    const inputSelector = document.createElement('div');
    inputSelector.innerText = 'Upload file';
    inputSelector.classList.add('input-selector');
    element.querySelector('.input.file label').appendChild(inputSelector);
  }

  static injectContent(element) {
    if (element
            && element.classList.contains('file-field')
            && !element.querySelector('.input-selector')
    ) {
      this.addFileButton(element);
      this.addInputChangeDetection(element);
    }
  }
}
