import ApplicationController from '../support/application_controller';

export default class extends ApplicationController {
  static targets = [
    'venues',
  ];

  get venues() {
    return JSON.parse(this.data.get('venues'));
  }

  set venues(venues) {
    this.venuesTarget.dataset.searchableDropdownOptions = JSON.stringify(Object.entries(venues).map((item) => ({ id: item[0], value: item[1] })));
  }

  setVenues({ currentTarget }) {
    if (!this.hasVenuesTarget) return;

    this.venues = this.venues[currentTarget.value];
    Array.from(this.venuesTarget.querySelectorAll('input')).forEach((input) => {
      input.value = '';
      input.removeAttribute('disabled');
    });
  }
}
