import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['scrollBox', 'cpdRightArrow', 'cpdLeftArrow', 'scrollDot', 'carouselDots']

  get scrollDotsCount() {
    return this.scrollDotTargets.length;
  }

  connect() {
    this.activeDot = 1;
    if (this.hasScrollDotTarget) {
      this.scrollDotTargets[0].style.opacity = 1;
    }
    if (this.scrollBoxTarget.scrollWidth - this.scrollBoxTarget.clientWidth > 0) {
      this.cpdRightArrowTarget.className = 'cpd-landing-cards__top-button-right';
    }
  }

  cpdScrollSet(scrollValue) {
    let scrollMax = this.scrollBoxTarget.scrollWidth - this.scrollBoxTarget.clientWidth;
    if(scrollValue > 0 && scrollMax > 0)
      this.cpdLeftArrowTarget.className = 'cpd-landing-cards__top-button-left';
    else
      this.cpdLeftArrowTarget.className = 'cpd-landing-cards__top-button-left-disabled';
    if(scrollMax > 0 && scrollValue < scrollMax)
      this.cpdRightArrowTarget.className = 'cpd-landing-cards__top-button-right';
    else
      this.cpdRightArrowTarget.className = 'cpd-landing-cards__top-button-right-disabled';
  }

  cpdScroll(amount) {
    this.cpdScrollSet(this.scrollBoxTarget.scrollLeft + amount);
    this.scrollBoxTarget.scrollLeft += amount;
  }

  scrollLeft() {
    this.cpdScroll(-260);
  }

  scrollRight() {
    this.cpdScroll(260);
  }

  updateDots(value) {
    if(value != this.activeDot) {
      for (let scrollDot of this.scrollDotTargets) {
        if (parseInt(scrollDot.dataset.index, 10) === value) {
          scrollDot.style.opacity = 1;
        } else {
          scrollDot.style.opacity = 0.25;
        }
      }
      this.activeDot = value;
    }
  }

  updateScroll() {
    let xCoord = this.scrollBoxTarget.firstElementChild.getBoundingClientRect().x;
    if(xCoord < 0)
      xCoord = (xCoord * -1) + 100;
    else
      xCoord = (xCoord - 100) * -1;
    if(xCoord > 240)
      this.updateDots(Math.floor((xCoord / 250) + 1));
    else
      this.updateDots(1);
    this.cpdScrollSet(xCoord);
  }
}