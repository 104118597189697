import { Controller } from 'stimulus';

const CARD_WIDTH = 360;
const SCROLL_DOT_ACTIVE_CLASS = 'my-ecpd__scroller-dot--active';

export default class extends Controller {
  static targets = ['content', 'scrollDot', 'leftButton', 'rightButton']

  connect() {
    this.buttonState = null;
    this.setButtonState = this.setButtonState.bind(this);
    window.addEventListener('resize', this.setButtonState, { passive: true });
    this.setButtonState();
  }

  disconnect() {
    window.removeEventListener('resize', this.setButtonState);
  }

  setDotsState(selector) {
    for (let i = 0; i < this.scrollDotTargets.length; i++ ) {
      let scrollDot = this.scrollDotTargets[i];
      if (i === 0 && selector) {
        scrollDot.classList.add(SCROLL_DOT_ACTIVE_CLASS);
      } else {
        scrollDot.classList.remove(SCROLL_DOT_ACTIVE_CLASS);
      }
    }
  }
  
  setState(leftClassName, rightClassName, disabledBool, stateBool) {
    this.leftButtonTarget.className = leftClassName;
    this.rightButtonTarget.className = rightClassName;
    this.leftButtonTarget.disabled = disabledBool;
    this.rightButtonTarget.disabled = disabledBool;
    this.setDotsState(stateBool);
    this.buttonState = stateBool;
  }

  setButtonState() {
    if (this.contentTarget.lastElementChild?.offsetLeft + CARD_WIDTH > window.innerWidth) {
      if (!this.buttonState)
        this.setState('my-ecpd__scroller-left', 'my-ecpd__scroller-right', false, true);
    } else {
      if (this.buttonState || this.buttonState == null)
        this.setState('cpd-landing-cards__top-button-left-disabled', 'cpd-landing-cards__top-button-right-disabled', true, false);
    }
  }

  adjustDots(direction) {
    for (let i = 0; i < this.scrollDotTargets.length; i++ ) {
      let scrollDot = this.scrollDotTargets[i];
      if (!scrollDot.classList.contains(SCROLL_DOT_ACTIVE_CLASS)) continue;

      if (direction === 'left' && i > 0) {
        scrollDot.classList.remove(SCROLL_DOT_ACTIVE_CLASS);
        this._findScrollDot(i - 1).classList.add(SCROLL_DOT_ACTIVE_CLASS);
      } else if (direction === 'right' && i < this.scrollDotTargets.length - 1) {
        scrollDot.classList.remove(SCROLL_DOT_ACTIVE_CLASS);
        this._findScrollDot(i + 1).classList.add(SCROLL_DOT_ACTIVE_CLASS);
      }
      break;
    }
  }

  scrollLeft() {
    this.contentTarget.scrollLeft -= CARD_WIDTH;
    this.adjustDots('left');
  }

  scrollRight() {
    this.contentTarget.scrollLeft += CARD_WIDTH;
    this.adjustDots('right');
  }

  _findScrollDot(index) {
    for (let scrollDot of this.scrollDotTargets) {
      if (index === parseInt(scrollDot.dataset.index)) return scrollDot;
    }
  }
}