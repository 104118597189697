import { Controller } from 'stimulus';
import LinkObserver from '../helper-classes/link_observer';

export default class extends Controller {
  connect() {
    this.observer = new LinkObserver(
      ({ href }) => href.endsWith(".pdf", href.length) || href.split('/').some(word => ['PDFs', 'files'].includes(word)),
      ({ currentTarget: { href } }) => dataLayer.push({
        'event': 'downloads',
        'event_category':'Downloads',
        'event_action': href.split('.').last || 'pdf',
        'event_label': href
      })
    );
    this.observer.observe(this.element);
  }

  disconnect() {
    this.observer.disconnect();
  }
}
