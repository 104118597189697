/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'input',
    'firstElement',
    'secondElement',
    'location',
  ]

  get currentValue() {
    return this.hasInputTarget ? this.inputTarget.value.toLowerCase() : null;
  }

  get firstElementValue() {
    return this.firstElementTarget ? this.firstElementTarget.value.toLowerCase() : null;
  }

  get secondElementValue() {
    return this.secondElementTarget ? this.secondElementTarget.value.toLowerCase() : null;
  }

  get swapValue() {
    return this.data.has('swap-value') ? this.data.get('swap-value').toLowerCase() : null;
  }

  // hide the input and remove the name attribute so it never gets submitted
  set hiddenInput(inputElement) {
    inputElement.closest('.js-input-container').classList.add('display-none');
    inputElement.removeAttribute('name');
  }

  connect() {
    if (!(this.hasFirstElementTarget && this.hasSecondElementTarget)) return;

    this.firstElementNameValue = this.firstElementTarget.getAttribute('name');
    this.secondElementNameValue = this.secondElementTarget.getAttribute('name');

    this.doSwap();
  }

  showInput(inputElement, nameAttr) {
    inputElement.closest('.js-input-container').classList.remove('display-none');
    inputElement.setAttribute('name', nameAttr);
  }

  doSwap() {
    if (this.currentValue === this.swapValue) {
      this.hiddenInput = this.firstElementTarget;
      this.showInput(this.secondElementTarget, this.secondElementNameValue);
    } else {
      this.hiddenInput = this.secondElementTarget;
      this.showInput(this.firstElementTarget, this.firstElementNameValue);
    }
  }

  alwaysShowFirst() {
    this.hiddenInput = this.secondElementTarget;
    this.showInput(this.firstElementTarget, this.firstElementNameValue);
  }

  clearFields({ currentTarget }) {
    this.locationTargets.forEach((location) => {
      if (currentTarget !== location) location.value = '';
    });
  }
}
