/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-expressions */

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'extraContent',
    'expandButton',
    'container',
  ]

  connect() {
    const params = new URLSearchParams(window.location.search);
    if (params.get('q')) {
      const container = document.querySelector("#".concat(params.get('q')));
      if (container) {
        window.scrollTo(0, container.offsetTop);
      }
    }

    if (this.open) {
      if (this.hasExtraContentTarget) this.expand();
      this.hightlight();
    }
  }

  hightlight() {
    this.containerTarget.style.backgroundColor = '#EFEFF0';
  }

  get open() {
    return this.data.get('open') === 'true';
  }

  get isVisible() {
    return this.extraContentTarget.style.display === 'inline';
  }

  toggleContent() {
    this.isVisible ? this.contract() : this.expand();
  }

  expand() {
    this.extraContentTarget.style.display = 'inline';
    this.expandButtonTarget.innerText = '< less';
  }

  contract() {
    this.extraContentTarget.style.display = 'none';
    this.expandButtonTarget.innerText = 'more >';
  }

  setURL(event) {
    const state = { q: event.currentTarget.id, turbo: window.history.state.turbo };
    window.history.pushState(state, '', `${window.location.pathname}?q=${event.currentTarget.id}`);
  }

  scrollToFragment(event) {
    window.scrollTo(0, event.currentTarget.offsetTop);
    this.setURL(event);
  }
}
