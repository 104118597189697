import ApplicationController from '../support/application_controller';

export default class extends ApplicationController {
  static targets = [
    'module',
    'info',
    'message',
  ];

  connect() {
    this.disableModules();
  }

  disableModules() {
    this.infoTargets.forEach((infoItem) => {
      const productID = infoItem.dataset.product;
      const info = JSON.parse(infoItem.dataset.info);
      if (!info.enabled) this.disableModule(productID);
    });
  }

  disableModule(productID) {
    this.module(productID).setAttribute('disabled', 'true');
  }

  module(productID) {
    return this.moduleTargets.find((moduleItem) => moduleItem.value === productID);
  }

  info(productID) {
    return this.infoTargets.find((infoItem) => infoItem.dataset.product === productID);
  }

  message(productID) {
    return this.messageTargets.find((messageItem) => messageItem.dataset.product === productID);
  }

  infoByModuleID(moduleID) {
    // eslint-disable-next-line
    // Allows for int to string comparisons
    return this.infoTargets.find((infoItem) => JSON.parse(infoItem.dataset.info).module_id == moduleID);
  }

  toggleModule({ currentTarget }) {
    const productID = currentTarget.value;
    const modules = this.infoTargets;
    const info = JSON.parse(this.info(productID).dataset.info);
    const currentTargetID = info.module_id;

    modules.forEach((module) => {
      const moduleInfo = JSON.parse(module.dataset.info);
      if (moduleInfo.corequisites.length > 0) {
        moduleInfo.corequisites.forEach((corequisite) => {
          if (Number(corequisite.module_id) === currentTargetID) {
            const mainModule = this.module(module.dataset.product);
            this.toggleCheckedState(mainModule);
            mainModule.checked = false;
          }
        });
      }
    });

    this.checkCorequisites({ currentTarget });
  }

  checkCorequisites({ currentTarget }) {
    const productID = currentTarget.value;
    const info = JSON.parse(this.info(productID).dataset.info);
    const corequisiteModules = this.corequisiteModules(info.corequisites);

    corequisiteModules.forEach((corequisiteModule) => {
      const corequisiteMessage = this.message(corequisiteModule.value);
      if (currentTarget.checked && !corequisiteModule.getAttribute('disabled')) {
        corequisiteModule.checked = true;
        corequisiteMessage.innerText = `This module is corequisite to ${info.name}.`;
        corequisiteMessage.classList.remove('display-none');
      } else {
        corequisiteModule.checked = false;
        corequisiteMessage.classList.add('display-none');
      }
    });
  }

  toggleCheckedState(mainModule) {
    const productID = mainModule.value;
    const info = JSON.parse(this.info(productID).dataset.info);
    const corequisiteModules = this.corequisiteModules(info.corequisites);

    corequisiteModules.forEach((corequisiteModule) => {
      const corequisiteMessage = this.message(corequisiteModule.value);
      if (mainModule.checked) {
        corequisiteModule.checked = false;
        corequisiteMessage.classList.add('display-none');
      }
    });
  }

  corequisiteModules(corequisites) {
    return corequisites.reduce((items, { module_id }) => {
      const info = this.infoByModuleID(module_id);
      if (info) items.push(this.module(info.dataset.product));
      return items;
    }, []);
  }
}
