/* eslint-disable no-unused-expressions */

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'toggleMsg',
    'prevMemberToggle',
  ]

  get initialState() {
    return this.data.element.dataset.initialState === 'true';
  }

  get msgBlock() {
    return this.toggleMsgTarget.style.display === 'block';
  }

  set msgDisplay(value) {
    this.toggleMsgTarget.style.display = value;
  }

  connect() {
    if (this.initialState) {
      this.toggleMsgTarget.style.display = 'block';
    } else {
      this.toggleMsgTarget.style.display = 'none';
    }
  }

  toggleText() {
    this.msgBlock ? this.msgDisplay = 'none' : this.msgDisplay = 'block';
    this.displayMemberButton();
  }

  displayMemberButton() {
    const membershipButton = document.querySelector('.prevMemberButton');
    if (membershipButton) {
      this.prevMemberToggleTarget.checked ? membershipButton.style.display = 'none' : membershipButton.style.display = 'block';
    }
  }
}
