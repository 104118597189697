import { Controller } from 'stimulus';
import 'whatwg-fetch'; // polyfill for IE

export default class extends Controller {
  defaultOptions = {
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
  }

  connect() {
    const url = this.data.get('url');
    const requestOptions = this.data.get('options');
    const retries = parseInt(this.data.get('retries'), 10);
    const timeout = parseInt(this.data.get('timeout'), 10);

    this.fetchRetry(url, requestOptions, retries, timeout);
  }

  fetchRetry(url, requestOptions, retries, timeout) {
    const fetchOptions = Object.assign(this.defaultOptions, requestOptions);
    const load = () => {
      this.fetchRetry(url, fetchOptions, retries - 1, timeout);
    };
    fetch(url, fetchOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } if (response.status === 202) {
          throw ('payload not yet ready, retry...');
        }
      })
      .then((json) => {
        this.element.outerHTML = json.content;
        this.dispatchEvents();
        $(document).foundation();
      })
      .catch((error) => {
        if (retries <= 1) {
          throw error;
        }
        setTimeout(load, timeout);
      });
  }

  dispatchEvents() {
    const events = this.events();
    this.element.dispatchEvent(new Event('ajax:success'));
    if (events.length > 0) {
      events.forEach((event, i) => {
        if (event.length > 0) {
          window.dispatchEvent(new Event(event));
        }
      });
    }
  }

  events() {
    const eventTriggers = this.data.get('event-triggers');
    return eventTriggers.split(' ');
  }
}
