import ApplicationController from '../support/application_controller';
import { InputValidator } from '../helper-classes/input_validator';

export default class extends ApplicationController {
  static targets = [
    'input',
    'toggleable',
    'hideInput',
    'postcode',
    'postcodeInput',
  ];

  get currentValue() {
    if (this.hasPostcodeInputTarget) this.validatePostcodeInput();
    return this.hasInputTarget ? this.inputTarget.value : null;
  }

  get shouldShow() {
    return this.getValue('showValue')
      ? this.currentValue === this.getValue('showValue')
      : this.currentValue !== this.getValue('hideValue');
  }

  get shouldHide() {
    return this.getValue('hideValue')
      ? this.currentValue === this.getValue('hideValue')
      : this.currentValue !== this.getValue('showValue');
  }

  connect() {
    this.doToggle();
    if (this.hasPostcodeTarget) this.togglePostcode();
    if (this.hasHideInputTarget) this.hideAll();
  }

  validatePostcodeInput() {
    const postCodeLabel = this.postcodeTarget.querySelector('.js-input-container label');

    if (this.inputTarget.value === 'UK and Northern Ireland') {
      this.postcodeInputTarget.setAttribute('required', 'required');
      this.postcodeInputTarget.classList.remove('optional');
      this.postcodeInputTarget.classList.add('required');

      postCodeLabel.textContent = 'Postcode';
    } else {
      this.postcodeInputTarget.removeAttribute('required', 'required');
      this.postcodeInputTarget.classList.add('optional');
      this.postcodeInputTarget.classList.remove('required');

      postCodeLabel.textContent = 'Postcode (optional)';
    }

    InputValidator.validateRequired(this.postcodeInputTarget);
    InputValidator.validatePattern(this.postcodeInputTarget);
  }

  getValue(value) {
    return this.data.has(value) ? this.data.get(value) : null;
  }

  doToggle() {
    if (this.shouldShow) {
      this.toggleableTarget.classList.remove('display-none');
    } else {
      this.toggleableTarget.classList.add('display-none');
    }
  }

  hideAll() {
    if (this.shouldHide) {
      this.hideInputTarget.classList.add('display-none');
    } else {
      this.hideInputTarget.classList.remove('display-none');
    }
  }

  togglePostcode() {
    if (this.inputTarget.value !== '') {
      this.postcodeTarget.classList.remove('display-none');
    } else {
      this.postcodeTarget.classList.add('display-none');
    }
  }
}
