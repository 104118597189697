import { Controller } from 'stimulus';
import 'isotope-layout/dist/isotope.pkgd';

export default class extends Controller {
  static targets = ['country', 'area', 'surname', 'profiles']

  get country() {
    return this.countryTarget.value;
  }

  get area() {
    return this.areaTarget.value;
  }

  get surname() {
    return this.surnameTarget.value;
  }

  connect() {
    this.community = jQuery('.cfid-profiles').isotope({
      itemSelector: '.mix',
      layoutMode: 'fitRows'
    });
    this.select();
  }

  select() {
    let filter = '';
    if (this.country) filter += '.' + this.country;
    if (this.area) filter += '.' + this.area;
    if (this.surname) filter += '.' + this.surname;
    if (!filter) filter = '*';

    this.community.isotope({ filter });
  }
}