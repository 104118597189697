import ApplicationController from '../support/application_controller';
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = [
    'formContainer',
    'formStep',
    'stepError',
    'name',
    'email',
    'employer',
    'termsAndConditions',
    'submit',
    'next',
    'upload',
    'programme',
    'awards',
    'awardsGrade',
    'startDate',
    'endDate',
    'nFQ',
    'enrolFunnel',
    'detailsTab',
    'placeOfWork',
    'ucd',
    'directDebit',
    'professionalMembership',
    'modules',
    'designations',
    'payment',
    'checkout'
  ];

  connect() {
    if (this.hasFormContainerTarget && this.hasFormStepTarget) {
      this.getSteps();
      if (this.error) this.toggleStepError(this.error);
    }
    this.setButtonsValidation();
  }

  get error() {
    return this.data.get('error');
  }

  get currentStepElement() {
    return this.formContainerTarget.querySelector('.form-step__selector.active');
  }

  get currentStepIndex() {
    return this.getStepIndex(this.currentStepElement);
  }

  get formElement() {
    return this.formContainerTarget.closest('form');
  }

  addClasses = (element, classListArray) => {
    if (classListArray) {
      classListArray.forEach((classItem) => {
        element.classList.add(classItem);
      });
    }
  }

  getSteps() {
    if (this.formStepTargets.length) {
      const parent = document.createElement('div');
      this.addClasses(parent, ['grid-x', 'grid-margin-x', 'margin-bottom-2', 'text-uppercase', 'text-left', 'form-step-wrapper']);
      for (let i = 0; i < this.formStepTargets.length; i += 1) {
        const child = document.createElement('div');
        this.addClasses(child, ['cell', 'small-3', 'form-step__selector']);
        child.setAttribute('data-name', this.formStepTargets[i].getAttribute('data-name'));
        child.setAttribute('data-title', this.formStepTargets[i].getAttribute('data-title'));
        child.setAttribute('data-step-index', i.toString(10));
        const grandChild = document.createElement('div');
        grandChild.classList.add('selector__text');
        grandChild.innerText = this.formStepTargets[i].getAttribute('data-name');
        child.appendChild(grandChild);
        parent.appendChild(child);
      }
      const { firstChild } = this.formContainerTarget;
      this.formContainerTarget.insertBefore(parent, firstChild);
      this.initSteps(this.formContainerTarget.querySelectorAll('.form-step__selector')[0]);

      const beforeCache = () => {
        parent.remove();
        document.removeEventListener('turbo:before-cache', beforeCache);
      }
      document.addEventListener('turbo:before-cache', beforeCache);
    }
  }

  initSteps(firstStep) {
    this.setStepSelectorAsVisible(firstStep);
    this.setStepAsVisible(firstStep.getAttribute('data-name'));
  }

  setButtonsValidation() {
    const buttons = Array.from(this.formContainerTarget.querySelectorAll('.button[data-watch-form-validity]'));
    buttons.filter((button) => button.getAttribute('data-watch-form-validity') === 'true').forEach((button) => {
      if (button.offsetHeight && !this.inputsValid()) {
        button.classList.add('disabled');
        button.setAttribute('disabled', 'disabled');
      } else {
        button.classList.remove('disabled');
        button.removeAttribute('disabled');
      }
    });
  }

  getInputsWithinContainer = (inputContainer, visibleInputs) => {
    visibleInputs.push(inputContainer.querySelector("input:not([type='hidden']), select, textarea"));
  }

  getVisibleInputs() {
    const visibleInputs = [];
    const inputContainers = this.formContainerTarget.querySelectorAll('.js-input-container');
    for (const inputContainer of inputContainers) {
      const inputItem = inputContainer.querySelector("input:not([type='hidden']), select, textarea");
      if (inputContainer.offsetHeight && inputItem !== null && inputItem.getAttribute('disabled') !== 'true') {
        this.getInputsWithinContainer(inputContainer, visibleInputs);
      }
    }
    return visibleInputs;
  }

  getDependentInputs(visibleInputs) {
    const dependentInputs = [];
    const dependentInputContainers = this.formContainerTarget.querySelectorAll('.js-input-container[data-dependency]');
    const targetInputs = [];
    if (!dependentInputContainers.length) return [];
    for (const inputContainer of dependentInputContainers) {
      let [dependencyController, dependencyTarget] = inputContainer.dataset.dependency.split('.')
      const dependentInput = this.formContainerTarget
        .querySelector(`.js-input-container[data-${dependencyController}-target*="${dependencyTarget}"]`);
      if (!visibleInputs.includes(dependentInput.querySelector('input'))) {
        dependentInputs.push(dependentInput);
      }
    }
    for (const inputContainer of dependentInputs) {
      this.getInputsWithinContainer(inputContainer, targetInputs);
    }
    return targetInputs;
  }

  inputsValid() {
    const inputs = this.getVisibleInputs();
    const allInputs = inputs.concat(this.getDependentInputs(this.getVisibleInputs()));
    for (const input of allInputs) {
      if (!input.checkValidity() || (input.getAttribute('required') && input.disabled)) {
        return false;
      }
    }
    return true;
  }

  isDescendant(parent, child) {
    let node = child;
    while (node != null) {
      if (node == parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  runFormValidations(element) {
    if (element && element.currentTarget.classList.contains('disabled')) element.preventDefault();
    const inputs = this.getVisibleInputs();
    for (const input of inputs) {
      this.changeEvent(input);
    }
  }

  nextStep() {
    this.runFormValidations();
    const scrollItem = document.querySelector('.scrollable');
    if (scrollItem !== null) scrollItem.scrollTop = 0;
    if (!this.inputsValid()) return;

    this.currentStepElement.classList.add('completed');
    let nextStepElement = null;
    let nextStepIndex = this.currentStepIndex;
    do {
      nextStepIndex++;
      nextStepElement = this.formContainerTarget.querySelector(`[data-step-index="${nextStepIndex}"]`);
    } while (nextStepElement && nextStepElement.dataset.skip === 'true');

    if (!nextStepElement) {
      return;
    }

    this.setStepSelectorAsVisible(nextStepElement);
    this.setStepAsVisible(nextStepElement.getAttribute('data-name'));
    this.setButtonsValidation();
  }

  skipDDUnlessIrish() {
    let countryId = this.formStepTargets[this.currentStepIndex].querySelector('form').querySelector('#user_details_country').value;
    if (countryId === '100') { // Ireland country ID is 100
      document.querySelectorAll('[data-name="Pay Methods"]').forEach(x => x.style.display=null);
      delete document.querySelector('[data-name="Pay Methods"].form-step__selector').dataset.skip;
    } else {
      document.querySelectorAll('[data-name="Pay Methods"]').forEach(x => x.style.display='none');
      document.querySelector('[data-name="Pay Methods"].form-step__selector').dataset.skip='true';
    }
  }

  toggleStepError(error) {
    this.stepErrorTargets.forEach((stepError) => {
      if (stepError.parentElement.classList.contains('active')) {
        if (error) {
          stepError.style.display = 'block';
          stepError.classList.remove('display-none');
          stepError.innerHTML = error;
        } else {
          stepError.style.display = 'none';
          stepError.classList.add('display-none');
          stepError.innerHTML = '';
        }
      }
    });
  }

  formSuccess() {
    const loader_anim = document.getElementById('loader-anim');
    if(loader_anim){
      loader_anim.style.display = 'none';
    }
    this.toggleStepError('');
    this.nextStep();
  }

  get recaptchaController() {
    return this.getControllerByIdentifier('recaptcha');
  }

  formError(event) {
    const loader_anim = document.getElementById("loader-anim");
    if(loader_anim){
      loader_anim.style.display = "none";
    }
    if (this.recaptchaController) this.recaptchaController.loadRecaptcha();
    if (event.detail[0].errors) {
      if (event.detail[0].errors.text) {
        this.toggleStepError(event.detail[0].errors.text);
      } else {
        this.toggleStepError(event.detail[0].errors[0].text);
      }
    } else {
      const eventResult = event.detail[2];
      const response = JSON.parse(eventResult.response);
      this.toggleStepError(response.errors[0].text);
    }
  }

  previousStep() {
    const formTop = document.querySelector('.scrollable');
    if (this.currentStepIndex > 0) {
      formTop.scrollTop = 0;
      let previousStepElement = null;
      let previousStepIndex = this.currentStepIndex;
      do {
        previousStepIndex--;
        previousStepElement = this.formContainerTarget.querySelector(`[data-step-index="${previousStepIndex}"]`)
      } while (previousStepElement && previousStepElement.dataset.skip === 'true')
      previousStepElement.classList.remove('completed');
      this.setStepSelectorAsVisible(previousStepElement);
      this.setStepAsVisible(previousStepElement.getAttribute('data-name'));
    }
  }

  getParentStepSelector = (target) => {
    let node = target;
    while (node != null) {
      if (node.classList && node.classList.contains('form-step__selector')) {
        return node;
      }
      node = node.parentNode;
    }
    return false;
  }

  getStepIndex(target) {
    const selector = this.getParentStepSelector(target);
    if (selector) {
      return parseInt(selector.getAttribute('data-step-index'), 10);
    }
    return false;
  }

  setStepAsVisible(stepName) {
    const stepElements = this.formContainerTarget.querySelectorAll('.form-step');
    stepElements.forEach((stepElement) => {
      if (stepElement.getAttribute('data-name').toLowerCase() === stepName.toLowerCase()) {
        stepElement.classList.add('active');
        Rails.fire(stepElement, 'formstep:enter', [stepName.toLowerCase()])
      } else {
        stepElement.classList.remove('active');
      }
    });
  }

  setStepSelectorAsVisible(step) {
    const stepSelectorElements = this.formContainerTarget.querySelectorAll('.form-step__selector');
    const stepTitle = document.querySelector('.js-step-title');
    const exitModals = document.querySelectorAll('.exit-flow-modal, a.programmes-card-small__link');

    stepSelectorElements.forEach((stepSelectorElement) => {
      if (this.isDescendant(stepSelectorElement, step)) {
        stepSelectorElement.classList.add('active');
      } else {
        stepSelectorElement.classList.remove('active');
      }
    });
    if (stepTitle && this.currentStepElement.dataset.title) {
      stepTitle.textContent = this.currentStepElement.dataset.title;
    }
    if (stepTitle) {
      if (stepTitle.textContent === 'Confirm') {
        exitModals.forEach((button) => {
          button.classList.add('display-none');
        });
      }
    }
  }

  // ANALYTICS
  notPreviouslyPushed(step) {
    return !window.dataLayer.some(x => x['express_interest_steps'] === step);
  }


  setGaFunnelName(){
    if(this.notPreviouslyPushed("Name"))
      this.setGaFunnelTags(this.nameTarget.dataset.event, 'Name');
  }

  setGaFunnelEmail(){
    if(this.notPreviouslyPushed("Email"))
      this.setGaFunnelTags(this.nameTarget.dataset.event, 'Email');
  }

  setGaFunnelEmployer(){
    if(this.notPreviouslyPushed("Employer"))
      this.setGaFunnelTags(this.nameTarget.dataset.event, 'Employer');
  }

  setGaFunnelTermsAndConditions(){
    if(this.notPreviouslyPushed('Terms and Conditions'))
      this.setGaFunnelTags(this.termsAndConditionsTarget.dataset.event, 'Terms and Conditions');
  }

  setGaFunnelTags(label, step){
    dataLayer.push({
      'event': 'express_interest_funnel',
      'event_category': 'Express Interest',
      'event_action': 'Form Submission',
      'event_label': label.trim(),
      'express_interest_steps': step
    });
  }

  setGaTags(){
    if (this.inputsValid() == true) {
      dataLayer.push({
        'event': 'express_interest',
        'event_category':'Express Interest',
        'event_action': 'Form Submission',
        'event_label': this.submitTarget.dataset.event
      });
    }
  }

  setEventGaTags(){
    if (this.inputsValid() == true) {
      dataLayer.push({
        'event': 'events_registration',
        'event_category': 'Events',
        'event_action': this.submitTarget.dataset.event,
        'event_label': 'Success',
        'user_type': this.submitTarget.dataset.userType || ''
      });
    }
  }

  gaRegisterForEvent(){
    dataLayer.push({
      'event': 'events_registration',
      'event_category':'Events',
      'event_action': this.nextTarget.dataset.event,
      'event_label':'Success',
      'user_type' : this.nextTarget.dataset.user
    });
  }

  setApplicationGaTags(){
    if (this.inputsValid() == true) {
      dataLayer.push({
        'event': 'applications',
        'event_category': 'Applications',
        'event_action': 'Applied: ' + this.submitTarget.dataset.slug,
        'event_label': 'Success',
        'programme_name': this.submitTarget.dataset.slug
      });
    }
  }

  setGaApplicationsAwardsGrade(){
    this.applicationFunnelTags(this.awardsGradeTarget.dataset.title, this.awardsGradeTarget.dataset.step); // event action, programme name, step name
  }

  setGaApplicationsStartDate(){
    this.applicationFunnelTags(this.startDateTarget.dataset.title, this.startDateTarget.dataset.step); // event action, programme name, step name
  }

  setGaApplicationsEndDate(){
    this.applicationFunnelTags(this.endDateTarget.dataset.title, this.endDateTarget.dataset.step); // event action, programme name, step name
  }

  setGaApplicationsNFQ(){
    this.applicationFunnelTags(this.nFQTarget.dataset.title, this.nFQTarget.dataset.step); // event action, programme name, step name
  }

  setGaApplicationsAwards(){
    this.applicationFunnelTags(this.awardsTarget.dataset.title, this.awardsTarget.dataset.step); // event action, programme name, step name
  }

  setGaApplicationsProgramme(){
    this.applicationFunnelTags(this.programmeTarget.dataset.title, this.programmeTarget.dataset.step); // event action, programme name, step name
  }

  setGaApplicationsUpload(){
    this.applicationFunnelTags(this.uploadTarget.dataset.title, this.uploadTarget.dataset.step); // event action, programme name, step name
  }

  applicationFunnelTags(title, step){
    if(step == 'upload')
    {
      dataLayer.push({
        'event': 'applications_funnel',
        'event_category': 'Applications',
        'event_action': 'Applied: ' + title.trim(),
        'event_label': step,
        'programme_name': title,
        'application_funnel_steps': step
      });
    }
    else
    {
      for (let i = 0; i < window.dataLayer.length; i++){
        if(window.dataLayer[i]["event_label"] && window.dataLayer[i]["event_label"] == step){
          break;
        }
        if(i == window.dataLayer.length - 1)
        {
          dataLayer.push({
            'event': 'applications_funnel',
            'event_category': 'Applications',
            'event_action': 'Applied: ' + title.trim(),
            'event_label': step,
            'programme_name': title,
            'application_funnel_steps': step
          });
        }
      }
    }
  }

  gaModules(){
    this.gaEnrollmentsFunnel(this.modulesTarget.dataset.title, this.modulesTarget.dataset.name);
  }

  gaDirectDebit(){
    if(this.directDebitTarget.dataset.gaCheck == 'event')
      this.gaEnrollmentsFunnel('Add Attendants', this.directDebitTarget.dataset.name);
    else
      this.gaEnrollmentsFunnel(this.directDebitTarget.dataset.title, this.directDebitTarget.dataset.name);
  }

  gaPayMethods(){
    this.gaEnrollmentsFunnel(this.ucdTarget.dataset.title, this.ucdTarget.dataset.name)
  }

  gaUcd(){
    if(this.placeOfWorkTarget.dataset.gaCheck == 'event')
      this.gaEnrollmentsFunnel('Pay Methods', this.placeOfWorkTarget.dataset.name)
    else
      this.gaEnrollmentsFunnel(this.placeOfWorkTarget.dataset.title, this.placeOfWorkTarget.dataset.name)
  }

  gaDetailsTab(){
    this.gaEnrollmentsFunnel(this.detailsTabTarget.dataset.title, this.detailsTabTarget.dataset.name)
  }

  gaEnrollmentsFunnel(title, name){
    if (this.inputsValid() == true) {
      dataLayer.push({
        'event': 'enrollments_funnel',
        'event_category': 'Enrollments',
        'event_action': 'Enrolled: ' + name,
        'event_label': title,
        'programme_name': name,
        'enrollments_step': title
      });
    }
  }

  gaUpdatedProducts(){
    for(let i = 0; window.dataLayer[i]; i++) {
      if(window.dataLayer[i].event == 'begin_checkout')
        return(window.dataLayer[i].ecommerce.checkout.products[0]);
    }
  }

  gaCheckout(){
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      'event': 'add_payment_info',
      'event_category': 'Enhanced Ecommerce',
      'event_action': 'Checkout',
      'event_label': 'Step 2: Payment Details',
      'programme_name': this.checkoutTarget.dataset.pname,
      'ecommerce': {
        'currencyCode': 'EUR',
        'checkout': {
          'actionField': { 'step': 2, 'option': 'Payment' },
          'products': [this.gaUpdatedProducts()]
        }
      }
    });
  }
}
