export class KeypressHelper {
  static isNumericInput(key, shiftKey) {
    return this.isNumber(key, shiftKey)
      || this.isNumpadNumber(key)
      || this.isDirectionKey(key)
      || this.isEscapeKey(key)
      || this.isBackspaceKey(key);
  }

  static isNumber(key, shiftKey) {
    const charCode = String.fromCharCode(key);
    return /^[0-9]*$/.test(charCode) && !shiftKey;
  }

  static isNumpadNumber(key) {
    return key >= 96 && key <= 105;
  }

  static isDirectionKey(key) {
    return key >= 37 && key <= 40;
  }

  static isEscapeKey(key) {
    return key === 27;
  }

  static isBackspaceKey(key) {
    return key === 8;
  }
}
