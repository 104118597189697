/* eslint-disable prefer-destructuring */
import { Controller } from 'stimulus';
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  static targets = [
    'errorMessage',
    'input',
  ];

  get symbol() {
    return this.data.get('symbol');
  }

  get isMobile() {
    return this.inputTarget.dataset.type === 'mobile';
  }

  connect() {
    setTimeout(() => {
      this.intlInitialize();
      this.resetInput();
    }, 50);
  }

  intlInitialize() {
    const controller = this;
    const placeholder = this.inputTarget.placeholder;
    this.inputTarget.placeholder = '';
    const required = this.inputTarget.required;
    this.intlTel = intlTelInput(this.inputTarget, {
      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.11/js/utils.js',
      initialCountry: 'ie',
      preferredCountries: ['ie', 'gb'],
      hiddenInput: controller.symbol,
      placeholderNumberType: this.isMobile ? 'MOBILE' : 'FIXED_LINE',
      customPlaceholder(selectedCountryPlaceholder) {
        return `${placeholder} ${required ? '' : '(optional) '}(${selectedCountryPlaceholder})`;
      },
    });
    this.inputTarget.addEventListener('countrychange', () => controller.checkValidity());
  }

  setError() {
    this.inputTarget.classList.add('phone-number-input__error');
    this.errorMessageTarget.classList.remove('display-none');
    this.checkErrorCodes();
    this.inputTarget.setAttribute('pattern', /^\b$/g);
  }

  checkValidity() {
    const numberType = this.intlTel.getNumberType();
    const validNumber = this.intlTel.isValidNumber() && this.checkCustomValidation(numberType);

    this.resetInput();

    if (this.inputTarget.value.trim()) {
      if (validNumber) {
        this.inputTarget.classList.add('phone-number-input__valid');
        this.inputTarget.removeAttribute('pattern');
      } else
        this.setError();
    }
    else if(this.inputTarget.value == '')
      this.setError();
  }

  resetInput() {
    this.inputTarget.classList.remove('phone-number-input__error');
    this.inputTarget.classList.remove('phone-number-input__valid');
    this.errorMessageTarget.innerHTML = '';
    this.errorMessageTarget.classList.add('display-none');
  }

  checkCustomValidation(numberType) {
    if (this.isMobile) {
      return this.constructor.checkForValidMobileNumber(numberType);
    }
    return true;
  }

  static checkForValidMobileNumber(numberType) {
    if (numberType === intlTelInputUtils.numberType.MOBILE || numberType === intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE) {
      return true;
    }
    return false;
  }

  checkErrorCodes() {
    const errorCode = this.intlTel.getValidationError();
    const errorMap = [
      `Please enter a valid ${this.isMobile ? 'mobile ' : ''}number`,
      'Invalid country code',
      'Too short - invalid number',
      'Too long - invalid number',
      'Please enter a valid number',
    ];

    if (errorCode < 0) {
      this.errorMessageTarget.innerHTML = errorMap[0];
    } else {
      this.errorMessageTarget.innerHTML = errorMap[errorCode];
    }
  }
}
