/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import ApplicationController from '../support/application_controller';

export default class extends ApplicationController {
  static targets = [
    'labelInput',
    'valueInput',
    'list',
  ];

  get options() {
    return JSON.parse(this.data.get('options'));
  }

  get value() {
    return this.labelInputTarget.value;
  }

  get allListItems() {
    return this.listTarget.querySelectorAll('.searchable-items__item');
  }

  set focusedItem(itemIndex) {
    const items = this.allListItems;
    const activeItem = items[itemIndex];

    // remove any active item classes
    Array.from(this.listTarget.querySelectorAll('.searchable-items__item--active'))
      .forEach((item) => {
        item && item.classList.remove('searchable-items__item--active');
      });
    if (activeItem) {
      activeItem.classList.add('searchable-items__item--active');
      this.scrollItemIntoView(activeItem);
    }
  }

  set valueAndTriggerChange(value) {
    this.labelInputTarget.value = value;
    this.clearListItems();
    this.labelInputTarget.focus();
    this.changeEvent(this.labelInputTarget)
  }

  connect() {
    this.activeListItem = -1;
    const list = document.createElement('div');
    list.setAttribute('class', 'searchable-items');
    list.setAttribute(`data-${this.identifier}-target`, 'list');
    this.labelInputTarget.parentNode.appendChild(list);
    this.toggleDropdownIcon();
  }

  findItems(value, listData) {
    this.activeListItem = -1;
    return listData.map((listItem) => listItem.value).filter((item) => item.substr(0, value.length).toUpperCase() === value.toUpperCase());
  }

  onClickOption(event) {
    const itemValue = event.currentTarget.getAttribute('data-value');
    this.valueAndTriggerChange = itemValue;
  }

  onKeyDownOption(listIndex) {
    const items = this.listTarget.querySelectorAll('.searchable-items__item');
    const selectedItem = items[listIndex];

    if (!selectedItem) return;

    this.valueAndTriggerChange = selectedItem.getAttribute('data-value');
  }

  hoverActiveItem(event) {
    const hoverIndex = event.target.getAttribute('data-index');
    this.activeListItem = hoverIndex;
    this.focusedItem = hoverIndex;
  }

  addListItems(filteredList) {
    const { value } = this;
    const listItems = filteredList.map((item, index) => this.listTarget.appendChild(this.createListElement(item, index, value)));
  }

  createListElement(item, index, value) {
    const itemElement = document.createElement('div');
    itemElement.setAttribute('data-value', item);
    itemElement.setAttribute('data-index', index);
    itemElement.setAttribute('class', 'searchable-items__item');
    itemElement.setAttribute('data-action', `click->${this.identifier}#onClickOption mouseover->${this.identifier}#hoverActiveItem`);
    itemElement.innerHTML = `<strong>${item.substr(0, value.length)}</strong>`;
    itemElement.innerHTML += item.substr(value.length);
    return itemElement;
  }

  closeList() {
    setTimeout(() => {
      this.clearListItems();
      this.toggleDropdownIcon();
    }, 300);
  }

  clearListItems() {
    this.listTarget.innerHTML = '';
  }

  searchList() {
    this.clearListItems();
    this.addListItems(this.findItems(this.value, this.options));
    this.toggleDropdownIcon();
  }

  // if there is just one match, and it is exact, set it as the value
  // else clear the field
  validateValue() {
    const match = this.options.find((item) => item.value.substr(0, this.value.length).toUpperCase() === this.value.toUpperCase());

    this.labelInputTarget.value = match ? match.value : null;
    this.valueInputTarget.value = match ? match.id : null;
  }

  toggleDropdownIcon() {
    this.value !== ''
      ? this.labelInputTarget.classList.add('no-icon')
      : this.labelInputTarget.classList.remove('no-icon');
  }

  scrollItemIntoView(item) {
    const itemHeight = item.getBoundingClientRect().height;
    const containerHeight = this.listTarget.getBoundingClientRect().height;
    const listBottomEdge = (this.listTarget.scrollTop + (containerHeight - itemHeight));

    if (item.offsetTop > listBottomEdge) {
      this.listTarget.scrollTop += itemHeight;
    }

    if (item.offsetTop < this.listTarget.scrollTop) {
      this.listTarget.scrollTop -= itemHeight;
    }
  }

  navigateList(event) {
    switch (event.keyCode) {
      case 40: // select next item
        if ((this.activeListItem + 1) < this.allListItems.length) {
          this.activeListItem += 1;
          this.focusedItem = this.activeListItem;
        }
        break;

      case 38: // select previous item
        if ((this.activeListItem - 1) >= 0) {
          this.activeListItem -= 1;
          this.focusedItem = this.activeListItem;
        }
        break;

      case 13:
        event.preventDefault();
        this.onKeyDownOption(this.activeListItem);
        break;

      default: break;
    }
  }
}
