import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'base',
    'form',
    'confirmations',
    'returnSubmit',
    'checkbox',
    'warning',
    'error',
    'container'
  ]

  static values = {
    path: String,
    shortName: String,
    year: String,
    hasNoShortfall: Boolean
  }

  connect() {
    this.checkboxTarget.checked = false;
    this.hasNoShortfallValue = this.containerTarget.dataset.annualReturnsHasNoShortfall === 'true';
  }

  done() {
    this.switchComponent(this.confirmationsTarget, this.baseTarget);
  }

  form() {
    this.switchComponent(this.baseTarget, this.formTarget);
    this.checkboxTarget.checked = false;
  }

  checkbox({ currentTarget: { checked }}) {
    this.returnSubmitTarget.disabled = !checked;
  }

  async sendAnnualReturn(hide, show) {
    let submitButton = this.warningTarget.querySelector('.cpd-annual-returns__disabled-submit-anyway');
    let xhr = new XMLHttpRequest();
    xhr.open("POST", this.pathValue, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if(!xhr.response.includes('content')) {
          this.switchComponent(hide, this.errorTarget);
          this.errorTarget.querySelector('#annual-error-text').innerHTML = xhr.response;
        }
        else
          this.switchComponent(hide, show);
        if (submitButton)
          submitButton.className = 'cpd-annual-returns__submit-anyway';
      }
    }
    xhr.send(JSON.stringify({
      annual_return: {
        scheme_short_name: this.shortNameValue,
        return_year: this.yearValue
      }
    }))
  }

  warningCancel() {
    this.switchComponent(this.warningTarget, this.formTarget);
    this.checkboxTarget.checked = false;
  }

  warningSubmit() {
    this.warningTarget.querySelector('.cpd-annual-returns__submit-anyway').className += ' cpd-annual-returns__disabled-submit-anyway';
    this.sendAnnualReturn(this.warningTarget, this.confirmationsTarget);
  }

  complete() {
    this.formTarget.querySelector('.cpd-annual-returns__button').disabled = true;
    if (this.hasNoShortfallValue) {
      this.sendAnnualReturn(this.formTarget, this.confirmationsTarget);
    } else {
      this.switchComponent(this.formTarget, this.warningTarget);
    } 
  }

  switchComponent(hide, show) {
    hide.classList.add('display-none');
    show.classList.remove('display-none');
  }

  tryAgain() {
    this.switchComponent(this.errorTarget, this.baseTarget);
  }
}
