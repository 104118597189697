import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  initValue = '';

  static targets = [
    'value',
    'valueContainer',
    'hiddenValue',
    'form',
    'cancelButton',
    'verifyButton',
    'verifyInputContainer',
    'verifyInput',
  ];

  connect() {
    this.initValue = this.hasValueTarget ? this.valueTarget.value : '';
  }

  handleChange(e) {
    this.valueContainerTarget.setAttribute('data-dependency', this.verifyInputContainerTarget.dataset.dependencytarget);
    this.hiddenValueTarget.value = e.currentTarget.value;
    const isEditing = this.initValue && e.currentTarget.value !== this.initValue;
    this.toggleButtonChange(isEditing);
    if (this.hasVerifyInputTarget) {
      this.toggleVisibility(this.verifyInputContainerTarget, false);
      this.verifyInputTarget.value = '';
    }
  }

  toggleButtonChange(isEditing) {
    this.hasCancelButtonTarget ? this.toggleVisibility(this.cancelButtonTarget, true) : null;
    this.hasVerifyButtonTarget ? this.toggleVisibility(this.verifyButtonTarget, true) : null;
    this.hasCancelButtonTarget ? this.toggleEnabled(this.cancelButtonTarget, true) : null;
    this.hasVerifyButtonTarget ? this.toggleEnabled(this.verifyButtonTarget, this.canVerify(this.valueTarget.value, isEditing)) : null;
  }

  canVerify(value, isEditing) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(value) && isEditing;
  }

  toggleVisibility(element, visibility) {
    visibility ? element.classList.remove('display-none') : element.classList.add('display-none');
  }

  toggleEnabled(element, enabled) {
    enabled ? element.removeAttribute('disabled') : element.setAttribute('disabled', 'disabled');
    enabled ? element.classList.remove('disabled') : element.classList.add('disabled');
  }

  submitEmailVerification() {
    if (this.hasFormTarget && !this.verifyButtonTarget.getAttribute('disabled')) {
      this.toggleEnabled(this.cancelButtonTarget, false);
      this.toggleEnabled(this.verifyButtonTarget, false);
      Rails.fire(this.formTarget, 'submit');
    }
  }

  handleVerificationSuccess() {
    this.toggleVisibility(this.verifyButtonTarget, false);
    this.toggleVisibility(this.verifyInputContainerTarget, true);
    this.toggleEnabled(this.cancelButtonTarget, true);
  }

  cancelEmailChange() {
    if (this.cancelButtonTarget.getAttribute('disabled')) return;
    this.valueTarget.value = this.initValue;
    this.hasCancelButtonTarget ? this.toggleVisibility(this.cancelButtonTarget, false) : null;
    this.hasVerifyButtonTarget ? this.toggleVisibility(this.verifyButtonTarget, false) : null;
    this.hasVerifyInputContainerTarget ? this.toggleVisibility(this.verifyInputContainerTarget, false) : null;
    this.valueContainerTarget.removeAttribute('data-dependency');
  }
}
