import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let pagyPrev = this.element.querySelector('.prev');
    let pagyNext = this.element.querySelector('.next');

    if (pagyPrev) { pagyPrev.textContent = '< prev page'; }
    if (pagyNext) { pagyNext.textContent = 'next page >'; }
  }
}