import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'dataProtectionScroller',
    'submitButton',
    'dataProtectionCheckbox',
  ];

  removeInputsDisabled() {
    const inputsParent = document.querySelector('.user_data_protection');
    const inputs = inputsParent.querySelectorAll('input');
    for (const input of inputs) {
      setTimeout(() => {
        input.getAttribute('disabled') ? input.removeAttribute('disabled') : null
      })
    }
  }

  handleDataProtectionScroll() {
    if (this.dataProtectionScrollerTarget.scrollTop
      + this.dataProtectionScrollerTarget.clientHeight
      >= this.dataProtectionScrollerTarget.scrollHeight) {
      this.removeInputsDisabled();
    }
  }

  submit() {
    if (!this.submitButtonTarget.classList.contains('disabled')) {
      window.location.href = '/users/my-institute';
    }
  }
}
