import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'userNav',
    'container',
    'icon',
    'column',
    'header',
    'meganav',
    'meganavMenu',
  ]

  connect() {
    const userNavExists = document.querySelector('.user-logged-in__drop-down');
    const userLoggedIn = document.querySelector('.user-logged-in')

    if (userNavExists) {
      document.addEventListener('keydown', (e) => {
        e.keyCode === 27 ? this.userNavTarget.setAttribute('data-user-nav-active', 'false') : null;
      });

      document.addEventListener('click', (e) => {
        if (!this.userNavTarget.contains(e.target)) {
          this.userNavTarget.setAttribute('data-user-nav-active', 'false');
        }
      });
      userLoggedIn.style.width = userLoggedIn.offsetHeight - 70 + userLoggedIn.offsetWidth + 'px'
    }

    $('.page-wrapper').on('click', () => {
      this.hideMeganavs();
    });

    if (window.location.href.indexOf('programme') > -1) {
      $('.hero-wrapper').css('padding-top', '130px');
    }
  }

  toggleNav() {
    this.switchDataAttr(this.containerTarget);
    this.switchDataAttr(this.iconTarget);
    if (this.containerTarget.dataset.mobileMenuActive === 'true') {
      this.headerWasNormal = this.headerTarget.classList.contains('header--is-normal');
      this.headerTarget.classList.add('header--is-normal');
    } else {
      if (!this.headerWasNormal) {
        this.headerTarget.classList.remove('header--is-normal');
      }
      this.hideMeganavs();
    }
  }

  switchDataAttr(targetElement) {
    targetElement.getAttribute('data-mobile-menu-active') !== 'true'
      ? targetElement.setAttribute('data-mobile-menu-active', 'true')
      : targetElement.setAttribute('data-mobile-menu-active', 'false');
  }

  showUserNav(e) {
    e.stopPropagation();
    this.userNavTarget.getAttribute('data-user-nav-active') !== 'true'
      ? this.userNavTarget.setAttribute('data-user-nav-active', 'true')
      : this.userNavTarget.setAttribute('data-user-nav-active', 'false');
  }

  showColumn(column) {
    this.hideColumns();
    column.classList.add('active');
  }

  hideColumns() {
    for (let column of this.columnTargets) {
      column.classList.remove('active');
    }
  }

  toggleColumn({ currentTarget: { parentElement } }) {
    if (parentElement.classList.contains('active')) {
      this.hideColumns();
    } else {
      this.showColumn(parentElement);
    }
  }

  hideMeganavs() {
    for (let meganav of this.meganavTargets) {
      meganav.style.display = 'none';
    }
    for (let meganavMenu of this.meganavMenuTargets) {
      delete meganavMenu.dataset.active;
    }
    this.headerTarget.classList.remove('header--is-white');
    this.hideColumns();
  }

  toggleMeganav({ currentTarget: { dataset: { meganav, active } } }) {
    if (active === 'true') {
      this.hideMeganavs();
    } else {
      for (let m of this.meganavTargets) {
        m.style.display = m.dataset.meganav === meganav ? 'block' : 'none';
      }
      for (let m of this.meganavMenuTargets) {
        if (m.dataset.meganav === meganav) {
          m.dataset.active = 'true';
        } else {
          delete m.dataset.active;
        }
      }
      this.headerTarget.classList.add('header--is-white');
      this.hideColumns();
    }
  }
}
