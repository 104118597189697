/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { Controller } from 'stimulus';
import { InputValidator } from '../helper-classes/input_validator';
import { FileInputHelper } from '../helper-classes/file_input_helper';
import { KeypressHelper } from '../helper-classes/keypress_helper';

export default class extends Controller {
  static targets = [
    'input',
    'inputContainer',
    'inputMessage',
    'inputPassword',
    'inputMatchPassword',
  ];

  connect() {
    this.disableEmptyOptions();
    FileInputHelper.injectContent(this.inputContainerTarget);
    if (this.hasInputTarget && this.inputTarget.value) {
      this.setContainerFocus();
    }
  }

  setContainerFocus() {
    this.inputContainerTarget.classList.add('focused');
  }

  focus() {
    this.inputTarget.focus();
  }

  disableEmptyOptions() {
    if(this.hasInputTarget) {
      const options = this.inputTarget.getElementsByTagName('option');
      for (const option of options) {
        this.setOptionDisabled(option);
      }
    }
  }

  setOptionDisabled(option) {
    if (!option.getAttribute('value')) {
      option.setAttribute('disabled', 'disabled');
    }
  }

  removeInputFocus() {
    if (!this.inputTarget.value) {
      this.inputContainerTarget.classList.remove('focused');
      this.validate(this.inputTarget);
    }
  }

  clear() {
    let event;
    if (typeof (Event) === 'function') {
      event = new Event('change');
    } else {
      event = document.createEvent('Event');
      event.initEvent('change', true, true);
    }
    const controller = this;
    this.inputTargets.forEach((input) => {
      input.value = '';
      input.dispatchEvent(event);

      controller.inputContainerTarget.classList.remove('focused');
      input.blur();
    });
  }

  validate(element) {
    if (this.fileInput(element)) return;
    element = element instanceof Event ? element.target : element;
    this.runValidations(element);
  }

  runValidations(element) {
    if (InputValidator.validateRequired(element)) {
      InputValidator.validatePattern(element)
    }
    if (element.getAttribute('type') == 'file') InputValidator.validateFiles(element);
  }

  handleFileUpload(element) {
    const uploader = element.currentTarget;
    if (!uploader || (uploader.getAttribute('type') !== 'file')) return;
    uploader.parentNode.querySelector('label').setAttribute(
      'data-before',
      Array.from(uploader.files).map(
        (x) => x.name,
      ).join(', '),
    );
  }

  updateAttributes(element, attribute, value) {
    const attributeValue = element.getAttribute(attribute);
    element.setAttribute(attribute, attributeValue ? `${attributeValue} ${value}` : value);
  }

  preventNumbers(e) {
    const unicode = e.charCode ? e.charCode : e.keyCode;
    if (unicode !== 8) {
      if (unicode > 47 && unicode < 58) e.preventDefault();
    }
  }

  preventAlpha(evt) {
    const e = evt || window.event;
    const key = (e.which) ? e.which : e.keyCode;
    if (KeypressHelper.isNumericInput(key, e.shiftKey)) return true;
    e.returnValue = false;
    if (e.preventDefault) e.preventDefault();
  }

  fileInput(element) {
    return !(element instanceof Event) && element.getAttribute('type') === 'file';
  }

  validateCheckbox() {
    const allCheckboxes = window.document.querySelectorAll('.checkbox-collection');
    const checkedCheckboxes = window.document.querySelectorAll('.checkbox-collection[type=checkbox]:checked');
    const designationDecription = window.document.querySelector('.accreditation__form-text');

    allCheckboxes.forEach((checkbox) => {
      if (checkedCheckboxes.length > 0) {
        checkbox.removeAttribute('required');
        designationDecription.classList.remove('has-error');
      } else {
        checkbox.setAttribute('required', 'required');
        designationDecription.classList.add('has-error');
      }
    });
  }

  activityDescriptionSelect() {
    const selectedOption = document.querySelector('.activity-select');
    const allFurtherDetails = document.querySelectorAll('.accreditation__further-details');
    this.displayFurtherDetails(selectedOption, allFurtherDetails);
  }

  displayFurtherDetails(selectedOption, optionDetails) {
    optionDetails.forEach((detail) => {
      selectedOption.value === detail.getAttribute('data-details')
        ? detail.style.display = 'block'
        : detail.style.display = 'none';
    });
  }
}
