import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'grid',
    'button',
    'title',
    'gridItem',
    'showIcon',
    'showHoverIcon',
    'hideIcon',
    'hideHoverIcon',
    'buttonLinkText',
  ];

  static outlets = [
    'programmes-card',
  ]

  static values = {
    hideItems: Boolean,
    search: Boolean,
  }

  connect() {
    this.displayElements();
    window.addEventListener('resize', () => this.displayElements());
  }

  displayElements() {
    this.reset();
    this.hideUnsearchedCards();
    this.hideHeading();
    this.hideButton();
    if (this.hideItemsValue === true) {
      this.cards.forEach((card, index) => {
        if (index >= this.gridSize) {
          card.visible = false;
        } else {
          card.visible = true;
        }
      });
    }
  }

  hideHeading() {
    if (this.cards.length === 0) {
      this.titleTarget.classList.add('display-none');
    } else {
      this.titleTarget.classList.remove('display-none');
    }
  }

  hideButton() {
    if (this.cards.length <= this.gridSize) {
      this.buttonTarget.classList.add('display-none');
    } else {
      this.buttonTarget.classList.remove('display-none');
    }
  }

  hideUnsearchedCards() {
    if (this.searchValue) {
      this.unsearchedCards.forEach(card => {
        card.visible = false;
      });
    }
  }

  get visibleElements() {
    return this.cards.filter((card) => card.visible);
  }

  get scopedCards() {
    return this._scopedCards = this._scopedCards || this.programmesCardOutlets.filter((card) => this.gridItemTargets.includes(card.element));
  }

  get searchedCards() {
    return this.scopedCards.filter((card) => card.search);
  }

  get unsearchedCards() {
    return this.scopedCards.filter((card) => !card.search);
  }

  get cards() {
    if (this.searchValue) {
      return this.searchedCards;
    }
    return this.scopedCards;
  }

  reset() {
    this.cards.forEach((card) => {
      card.visible = true;
    });
  }

  searchCards(searchValue, filters = []) {
    this.searchValue = searchValue.length > 0 || filters.length > 0;
    this.scopedCards.forEach((card) => {
      card.hide(searchValue, filters);
    });
    this.displayElements();
    return this.searchedCards;
  }

  showAll() {
    this.searchValue = false;
    this.reset();
    this.scopedCards.forEach((card) => {
      card.search = false;
    });
    this.displayElements();
  }

  toggleHide() {
    this.toggleText();
    this.toggleIcons();
    this.hideItemsValue = !this.hideItemsValue;
    this.displayElements();
  }

  toggleIcons() {
    this.showIconTarget.style.display = this.hideItemsValue ? 'none' : 'block';
    this.hideIconTarget.style.display = this.hideItemsValue ? 'block' : 'none';
  }

  showHoverIcons() {
    this.showHoverIconTarget.style.display = this.hideItemsValue ? 'block' : 'none';
    this.hideHoverIconTarget.style.display = this.hideItemsValue ? 'none' : 'block';
    this.showIconTarget.style.display = 'none';
    this.hideIconTarget.style.display = 'none';
  }

  hideHoverIcons() {
    this.showIconTarget.style.display = this.hideItemsValue ? 'block' : 'none';
    this.hideIconTarget.style.display = this.hideItemsValue ? 'none' : 'block';
    this.showHoverIconTarget.style.display = 'none';
    this.hideHoverIconTarget.style.display = 'none';
  }

  toggleText() {
    if (this.hideItemsValue === true) {
      this.buttonLinkTextTarget.textContent = this.buttonLinkTextTarget.textContent.replace('Load More', 'Load Less');
    } else {
      this.buttonLinkTextTarget.textContent = this.buttonLinkTextTarget.textContent.replace('Load Less', 'Load More');
    }
  }

  get gridSize() {
    return window.innerWidth >= 1024 ? '4' : '3';
  }

  get searchElements() {
    return this.scopedCards.filter((card) => card.search);
  }

  disconnect() {
    window.removeEventListener('resize', () => this.displayElements());
  }
}
