import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['download', 'older']

  showOlder() {
    for(let download of this.downloadTargets) {
      download.classList.remove('display-none');
    }
    this.olderTarget.classList.add('display-none');
  }
}