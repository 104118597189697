import { load } from 'recaptcha-v3';
import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

function isTurboPreview() {
  return !!document.querySelector('html[data-turbo-preview]');
}

export default class extends Controller {
  static targets = [
    'form',
    'input',
  ]

  get action() {
    return this.data.get('action');
  }

  get siteKey() {
    return this.data.get('site-key');
  }

  connect() {
    if (!isTurboPreview()) {
      this.loadRecaptcha();
    }
  }

  execute(event) {
    if (!this.hasInputTarget || this.inputTarget.value) return;

    this.element.querySelector('input[type=submit]').setAttribute('disabled', 'disabled')
    event.preventDefault();
    window.loadedRecaptcha.execute(this.action).then((token) => {
      this.inputTarget.value = token;
      Rails.fire(this.formTarget, 'submit');
    });
  }

  loadRecaptcha = () => {
    if (window.loadedRecaptcha) {
      document.body.append(window.loadedRecaptchaBadge);
      this.setupCleanup();
      return;
    }
    load(this.siteKey, {
      explicitRenderParameters: {
        container: document.getElementById('recaptcha-badge-container'),
        badge: 'bottomleft',
        size: 'invisible',
      },
    }).then((recaptcha) => {
      window.loadedRecaptcha = recaptcha;
      window.loadedRecaptchaBadge = document.getElementById('recaptcha-badge-container');
      this.setupCleanup();
    });
  }

  setupCleanup() {
    const cleanUp = () => {
      if(window.loadedRecaptchaBadge.parentNode)
        window.loadedRecaptchaBadge.parentNode.removeChild(window.loadedRecaptchaBadge);
      this.inputTarget.value = '';
      window.removeEventListener('turbo:before-cache', cleanUp);
    }
    window.addEventListener('turbo:before-cache', cleanUp, false);
  }
}
