import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'body',
  ];

  connect() {
    if (this.hasBodyTarget) this.setOverflow();
  }

  hasOverflow(el) {
    const curOverflow = el.style.overflow;
    if ( !curOverflow || curOverflow === "visible" )
      el.style.overflow = "hidden";
    const isOverflowing = el.clientWidth < el.scrollWidth
        || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;
  }

  handleClick(element, bodyElement) {
    element.addEventListener('click', function(event) {
      if(element.classList.contains('active')) {
        bodyElement.classList.remove('expanded');
        element.classList.remove('active');
        element.innerText = 'View more...';
      } else {
        bodyElement.classList.add('expanded');
        element.classList.add('active');
        element.innerText = 'View less >';
      }
    });
  }

  setOverflow() {
    const bodyElement = this.bodyTarget;
    if (!this.hasOverflow(bodyElement)) return;
    bodyElement.classList.add('has-overflow');
    const toggleElement = document.createElement('div');
    toggleElement.classList.add('news-toggle');
    toggleElement.classList.add('margin-top-2');
    toggleElement.innerText = 'View more >';
    this.handleClick(toggleElement, bodyElement);
    bodyElement.parentElement.appendChild(toggleElement);
  }
}
