import { Controller } from 'stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = [
    'swipe',
  ];

  connect() {
    this.swiper = new Swiper(this.swipeTarget, {
      loop: true,
      freeMode: true,
      loopFillGroupWithBlank: true,
      spaceBetween: 20,
      pagination: {
        el: '.logo-carousel-swiper-pagination',
        dynamicBullets: true,
        dynamicMainBullets: 4,
        clickable: true
      },
      navigation: {
        nextEl: '.logo-carousel-swiper-button-next',
        prevEl: '.logo-carousel-swiper-button-prev',
      },
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        480: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        640: {
          slidesPerView: 7,
          spaceBetween: 80,
        },
      },
    });
  }
}
