import { Controller } from 'stimulus';

export default class extends Controller {
  static outlets = [
    'programmes-card',
  ]

  static targets = [
    'sliderItem',
    'title',
  ]

  searchCards(searchValue, filters = []) {
    this.searchValue = searchValue.length > 0;
    this.scopedCards.forEach((card) => {
      card.hide(searchValue, filters);
    });
    this.scopedCards.forEach((card) => {
      card.visible = card.search;
    });
    this.hideHeading();
    return this.searchedCards.length;
  }

  hideHeading() {
    if (this.searchedCards.length === 0) {
      this.titleTarget.classList.add('display-none');
    } else {
      this.titleTarget.classList.remove('display-none');
    }
  }

  showAll() {
    this.scopedCards.forEach((card) => {
      card.visible = true;
    });
    this.titleTarget.classList.remove('display-none');
  }

  get scopedCards() {
    return this.programmesCardOutlets.filter((card) => this.sliderItemTargets.includes(card.element));
  }

  get searchedCards() {
    return this.scopedCards.filter((card) => card.search);
  }
}
