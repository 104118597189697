import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
  }

  scrollToId() {
    document.querySelector(this.data.element.dataset.scrollToValue).scrollIntoView({
      behavior: 'smooth'
    })
  }
}
