import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'loader',
    'frame',
    'term',
  ];

  connect() {
    const config = { attributes: true, attributeFilter: ['busy'] };
    const observer = new MutationObserver(this.addLoader.bind(this));
    observer.observe(this.frameTarget, config);
  }

  addLoader() {
    const loader = this.loaderTarget.cloneNode(true);
    loader.style.removeProperty('display');
    loader.style.setProperty('display', 'block');
    this.frameTarget.innerHTML = loader.outerHTML;
  }

  setLinksTarget() {
    this.termTargets.forEach((term) => {
      term.querySelectorAll('a').forEach((item) => {
        item.setAttribute('target', '_blank');
      });
    });
  }
}
