import { Controller } from 'stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = [
    'show'
  ];

  connect() {
    this.showNavigation();
    document.addEventListener("DOMContentLoaded", function() {
      // Select all links with an href that contains "guidelines-and-regulations"
      const links = document.querySelectorAll('a[href*="guidelines-and-regulations"]');

      // Loop through each link and append target="_blank"
      links.forEach(function(link) {
        link.setAttribute('target', '_blank');
      });
    });


  }

  showNavigation(){
    this.showTarget.parentNode.parentNode.classList.remove('p-designations');
  }
}
