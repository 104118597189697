import ApplicationController from '../support/application_controller';

import { showLoader, hideLoader } from './loader_controller';

export default class extends ApplicationController {
  static targets = [
    'bic',
    'cancelMessage',
    'directDebitInfo',
    'employerToggle',
    'employerToggleInput',
    'firstToggle',
    'futurePayment',
    'iban',
    'info',
    'payment',
    'thirdToggle',
    'toggle',
    'toggleComponent',
  ];

  get usersPath() {
    return this.element.dataset.usersPath;
  }

  get directDebitPath() {
    return this.element.dataset.directDebitPath;
  }

  connect() {
    this.setFormPath();
    this.checkToggleState();
  }

  futurePayments({ target }) {
    if (!this.hasEmployerToggleTarget) return;

    showLoader();
    target.style.visibility = 'hidden';
    fetch('/forms_api/future_payment')
      .then(response => response.json())
      .then(body => {
        hideLoader();
        target.style.visibility = null;
        const futurePaymentItems = body.future_payment;
        const { membership } = body;
        this.companyInvoiceItem = futurePaymentItems.find((item) => item.method_name === 'Company Invoice');
        this.personalInvoiceItem = futurePaymentItems.find((item) => item.method_name === 'Personal Invoice');
        this.directDebitItem = futurePaymentItems.find((item) => item.method_name === 'Direct Debit');
        if (this.companyInvoiceItem && membership) {
          this.employerToggleTarget.classList.remove('display-none');
          this.employerToggleState();
        } else if (this.companyInvoiceItem) {
          this.employerToggleTarget.classList.add('display-none');
          this.infoTarget.classList.add('display-none');
        } else {
          this.employerToggleTarget.classList.add('display-none');
          this.infoTarget.classList.remove('display-none');
        }
        if (this.personalInvoiceItem) {
          this.futurePaymentTarget.firstElementChild.value = this.personalInvoiceItem.id;
        }
      });
  }

  employerToggleState() {
    if (!this.employerToggleInputTarget.checked) {
      this.infoTarget.classList.remove('display-none');
    }
  }

  employerPaying() {
    if (this.employerToggleInputTarget.checked) {
      this.infoTarget.classList.add('display-none');
      this.infoTarget.classList.remove('display-block');
      this.futurePaymentTarget.firstElementChild.value = this.companyInvoiceItem.id;
    } else {
      this.checkToggles();
    }
  }

  checkToggles() {
    this.infoTarget.classList.remove('display-none');
    this.infoTarget.classList.add('display-block');
    if (this.firstToggleTarget.checked) {
      this.paymentTarget.classList.add('display-none');
    } else {
      this.paymentTarget.classList.remove('display-none');
    }
    if (this.thirdToggleTarget.checked) {
      if (this.directDebitItem) {
        this.futurePaymentTarget.firstElementChild.value = this.directDebitItem.id;
      }
    } else if (this.personalInvoiceItem) {
      this.futurePaymentTarget.firstElementChild.value = this.personalInvoiceItem.id;
    }
  }

  setFormPath = () => {
    setTimeout(() => {
      const currentTab = window.location.hash;
      const form = document.querySelector('.simple_form.edit_user');
      if (form) {
        if (currentTab === '#direct-debit') {
          form.setAttribute('action', `${this.directDebitPath}`);
          this.checkAccount();
        } else {
          form.setAttribute('action', `${this.usersPath}`);
        }
      }
    }, 500);
  }

  checkToggleState() {
    this.toggleToggles();
    this.toggleComponents();
  }

  toggleToggles() {
    const controller = this;
    this.toggleTargets.forEach((toggle) => {
      const required = toggle.dataset.required.split(',').filter((item) => item !== '');
      const parent = this.getParentComponent(toggle, 6);
      if (controller.activeToggleIds.filter((elem) => {
        return required.indexOf(elem) > -1;
      }).length === required.length) {
        parent.classList.remove('toggle--disabled');
        toggle.removeAttribute('disabled');
      } else {
        parent.classList.add('toggle--disabled');
        toggle.checked = false;
        toggle.setAttribute('disabled', true);
      }
    });
  }

  toggleComponents() {
    this.toggleComponentTargets.forEach((component) => {
      if (component.dataset.toggleOption === this.numToggled.toString()) {
        component.dataset.toggleActive = 'true';
        component.classList.add('display-block');
      } else {
        component.dataset.toggleActive = 'false';
        component.classList.remove('display-block');
      }
    });
  }

  get activeToggleIds() {
    return this.toggleTargets.filter((toggle) => toggle.checked).map((toggle) => toggle.dataset.id).sort();
  }

  get numToggled() {
    return this.toggleTargets.reduce((total, toggle) => {
      return total + (toggle.checked ? 1 : 0);
    }, 0);
  }

  checkAccount() {
    if (this.bicTarget.value !== '' || this.ibanTarget.value !== '') {
      if (this.hasCancelMessageTarget)
        this.cancelMessageTarget.classList.remove('display-none');
      this.toggleTargets.forEach((toggle) => {
        toggle.checked = 'checked';
        this.getParentComponent(toggle, 2).classList.add('toggle--disabled');
        this.getParentComponent(toggle, 5).classList.add('toggle--disabled');
      });
    }
    this.toggleComponents();
  }

  toggleOpacity() {
    if (this.thirdToggleTarget.checked) {
      this.directDebitInfoTarget.classList.remove('opacity-2');
    } else {
      this.directDebitInfoTarget.classList.add('opacity-2');
    }
  }
}
