import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import ApplicationController from '../support/application_controller';

export default class extends ApplicationController {
  static targets = [
    'grid',
  ]

  connect() {
    if (this.hasGridTarget) this.initializeMasonry();
  }

  initializeMasonry() {
    const msnry = new Masonry(this.gridTarget, {
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      percentPosition: true,
    });

    imagesLoaded(this.gridTarget).on('progress', () => {
      msnry.layout();
    });
  }
}
