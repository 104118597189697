import { Controller } from 'stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = [
    'heroCarousel',
  ];

  connect() {
    this.heroCarousel = new Swiper(this.heroCarouselTarget, {
      speed: 500,
      parallax: true,
      loop: true,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }
}
