import { Controller } from 'stimulus';

export default class extends Controller {
  clearModalInputs() {
    this.getAndResetInputs();
  }

  clearByEscapeKey(e) {
    if (e.keyCode === 27) {
      this.getAndResetInputs();
    }
  }

  getAndResetInputs() {
    this.inputContainer = window.document.querySelectorAll('.js-input-container');
    this.textarea = window.document.querySelector('textarea');

    this.inputContainer.forEach((input) => {
      input.classList.remove('has-message');
      input.classList.remove('error');
      input.classList.remove('focused');
      input.classList.remove('valid');
    });

    if (this.textarea) {
      this.textarea.value = '';
    }
  }
}
