import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'status',
    'employerToggle',
    'workInput',
    'button',
  ];

  error(response) {
    let message = '';

    if (Array.isArray(response.detail[0].message)) {
      message = response.detail[0].message.join('\n');
    } else {
      message = response.detail[0].message;
    }

    this.showMessage('error', message);
  }

  success(response) {
    this.showMessage('notice', response.detail[0].message);
    this.disableDirectDebitsInputs();
    this.disableActionButtons();
  }

  hideMessage() {
    this.statusTarget.setAttribute('data-hidden', '');
    this.statusTarget.classList.remove('notice', 'error');
    this.statusTarget.querySelector('.description').innerText = '';
    this.buttonTarget.classList.add('display-none');
  }

  clearWorkDetails() {
    if (this.employerToggleTarget.checked) return;

    this.workInputTargets.forEach((field) => {
      field.value = '';
      field.closest('.js-input-container').classList.remove('focused');
    });
  }

  showMessage(type, message) {
    this.statusTarget.removeAttribute('data-hidden');
    this.statusTarget.classList.remove(type === 'error' ? 'notice' : 'error');
    this.statusTarget.classList.add(type === 'error' ? 'error' : 'notice');
    this.buttonTarget.classList.remove('display-none');
    this.statusTarget.querySelector('.description').innerText = message;
  }

  disableActionButtons = () => {
    const saveBtn = document.querySelectorAll('.my-details__navigation--button input');
    const cancelBtn = document.querySelector('.cancel-button');
    const verificationInput = document.querySelector('.verification-input input');
    const emailInput = document.querySelector('.email-input');
    saveBtn.forEach((btn) => btn.classList.add('disabled'));
    cancelBtn.classList.add('disabled');
    cancelBtn.setAttribute('disabled', 'disabled');
    cancelBtn.classList.add('display-none');
    verificationInput.value = '';
    verificationInput.parentElement.parentElement.classList.add('display-none');
    emailInput.removeAttribute('data-dependency');
  }

  disableDirectDebitsInputs() {
    const debitUpdated = this.statusTarget.querySelector('.description').innerText.includes('Direct debit');
    const directDebitInputs = document.querySelectorAll('.direct-debit-container input');
    const inputContainers = document.querySelectorAll('.js-input-container');
    const clearBtns = document.querySelectorAll('.input__clear');
    if (debitUpdated) {
      this.disableInputs(directDebitInputs);
      this.removeValidationBorder(inputContainers);
      this.removeClearIcon(clearBtns);
    }
  }

  disableInputs = (elements) => {
    elements.forEach((element) => {
      element.classList.add('disabled');
      element.setAttribute('disabled', true);
    });
  };

  removeValidationBorder = (elements) => {
    elements.forEach((element) => {
      element.classList.remove('valid');
    });
  };

  removeClearIcon = (elements) => {
    elements.forEach((element) => {
      element.classList.remove('input__clear');
    });
  }
}
