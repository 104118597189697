import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { acceptPath: String }

  confirm() {
    let x = new XMLHttpRequest();
    x.open('GET', this.acceptPathValue);
    x.setRequestHeader('X_REQUESTED_WITH', 'xmlhttprequest');
    x.send();
  }
}