import { Controller } from 'stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = [
    'testimonial',
  ];

  connect() {
    this.testimonial = new Swiper(this.testimonialTarget, {
      speed: 500,
      parallax: true,
      loop: true,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.member-testimonial__right-arrow-orange',
        prevEl: '.member-testimonial__left-arrow-orange'
      }
    });
  }
}
