import { Controller } from 'stimulus';
import toastr from 'toastr';

export default class extends Controller {
  deleteEvent(e) {
    e.stopPropagation();
    this.disableDeleteBtn(e.target);
    const url = e.target.dataset.url;
    const fetchOptions = Object.assign(this.defaultOptions(), { method: 'DELETE' });

    fetch(url, fetchOptions)
      .then(response => {
        if (response.status === 204) {
          return;
        } else if (response.status === 400) {
          return response.json().then(json => {
            throw new Error(json.error);
          });
        } else {
          throw new Error('Could not delete event at this time. Please try again.');
        }
      })
      .then(() => {
        this.removeEventContainer(e.target)
      })
      .catch(error => {
        toastr.error(error.message, 'Error', {
          timeOut: 0,
          extendedTimeOut: 0,
          closeButton: true,
          allowHtml: true,
          positionClass: "toast-top-center"
        });
        this.enableDeleteBtn(e.target);
      })
  }

  defaultOptions() {
    return {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
      },
    }
  }

  removeEventContainer(deleteBtn) {
    deleteBtn.closest('.hours-breakdown__content-container').remove();
  }

  enableDeleteBtn(btn) {
    btn.querySelector('.hours-breakdown__content-delete-text').textContent = 'Delete';
    btn.querySelector('.hours-breakdown__content-delete-icon').style.display = null;
    btn.removeAttribute('disabled');
  }

  disableDeleteBtn(btn) {
    btn.querySelector('.hours-breakdown__content-delete-text').textContent = 'Processing...';
    btn.querySelector('.hours-breakdown__content-delete-icon').style.display = 'none';
    btn.setAttribute('disabled', 'disabled');
  }
}