/* eslint-disable no-unused-expressions */
import ApplicationController from '../support/application_controller';

export default class extends ApplicationController {
  static targets = [
    'location',
  ]

  clearFields({ currentTarget }) {
    this.locationTargets.forEach((location) => {
      if (currentTarget !== location) location.value = '';
    });
  }
}
