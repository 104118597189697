/* eslint-disable max-len */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'container',
  ];

  enableCheckbox = () => {
    if (this.checkBoxElement()) {
      if (this.containerTarget.scrollHeight - this.containerTarget.scrollTop <= this.containerTarget.clientHeight) {
        this.checkBoxElement().removeAttribute('disabled');
      }
    }
  }

  checkBoxElement = () => document.querySelector('.data-pro-hook');
}
