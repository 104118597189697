import { Controller } from 'stimulus';

export default class extends Controller {
  formSuccess({ detail: [response] }) {
    this.element.innerHTML = '';
    for (let child of response.body.children) {
      this.element.appendChild(child);
    }
  }
}
