import { Controller } from 'stimulus';

class ApplicationController extends Controller {
  getControllerByIdentifier(identifier) {
    return this.application.controllers.find((controller) => controller.context.identifier === identifier);
  }

  getControllersByIdentifier(identifier) {
    return this.application.controllers.filter((controller) => controller.context.identifier === identifier);
  }

  getControllerForElement(element, identifier) {
    return this.application.getControllerForElementAndIdentifier(element, identifier);
  }

  getParentComponent = (element, num) => {
    let parent = element;
    for (let i = 0; i < num; i += 1) {
      if (parent.parentNode) {
        parent = parent.parentNode;
      }
    }
    return parent;
  }

  changeEvent = (changeElement) => {
    let event;
    if (typeof (Event) === 'function') {
      event = new Event('change');
    } else {
      event = document.createEvent('Event');
      event.initEvent('change', true, true);
    }

    changeElement.dispatchEvent(event);
  }
}
export default ApplicationController;
