import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'component',
  ]

  switchComponent(element) {
    const item = element instanceof Event ? element.currentTarget : element;
    if (item.getAttribute('disabled') || item.classList.contains('disabled')) return;

    const activator = item.dataset.activator ? item.dataset.activator : item.dataset.identifier;
    this.componentTargets.forEach((component) => {
      if (component.dataset.identifier === activator) {
        component.classList.remove('display-none');
        component.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        component.classList.add('display-none');
      }
    });
  }

  renderComponent({ detail }) {
    const { switcherIdentifier } = detail[0];
    const item = this.componentTargets.find(
      (component) => component.dataset.identifier === switcherIdentifier,
    );
    item.innerHTML = detail[0].content;
    this.switchComponent(item);
  }
}
