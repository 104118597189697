import ApplicationController from '../support/application_controller';
import { InputValidator } from '../helper-classes/input_validator';

export default class extends ApplicationController {
  today = new Date();

  daysInMonth = 0;

  monthsInYear = 12;

  static targets = [
    'dobDay',
    'dobMonth',
    'dobYear',
  ];

  get caller() {
    return this.data.get('caller');
  }

  get preventFuture() {
    return this.data.get('prevent-future') === 'true';
  }

  get friend() {
    let friend = null;
    if (this.caller === 'to') {
      friend = this.getControllersByIdentifier('date-selector').find((controller) => controller.caller === 'from');
    } else if (this.caller === 'from') {
      friend = this.getControllersByIdentifier('date-selector').find((controller) => controller.caller === 'to');
    }
    return friend;
  }

  get date() {
    const day = this.dobDayTarget.value;
    const month = this.dobMonthTarget.value;
    const year = this.dobYearTarget.value;
    if (day !== '' && month !== '' && year !== '') return new Date(`${month}/${day}/${year}`);
    return null;
  }

  changeDate() {
    const month = parseInt(this.dobMonthTarget.value || 0, 10);
    const year = parseInt(this.dobYearTarget.value || 0, 10);
    this.daysInMonth = this.getDaysInMonth(month, year);
    this.monthsInYear = this.getMonthsInYear(year);
    this.resetOptions();
    this.dobMonthTarget.value = month || '';
    this.checkDateRange();
  }

  checkDateRange() {
    if (this.isDateRange) return;
    if (this.validDateRange) {
      this.dobYearTarget.setAttribute('pattern', /\D/);
      this.dobYearTarget.setAttribute('message', 'Please ensure TO date is after FROM date');
    } else {
      this.dobYearTarget.removeAttribute('pattern');
      this.friend.dobYearTarget.removeAttribute('pattern');
      InputValidator.removeInputErrorState(this.dobYearTarget);
      InputValidator.removeInputErrorState(this.friend.dobYearTarget);
    }
  }

  get isDateRange() {
    return this.caller === '' || this.date === null || this.friend.caller === '' || this.friend.date === null;
  }

  get validDateRange() {
    return (this.caller === 'to' && this.date < this.friend.date) || (this.caller === 'from' && this.date > this.friend.date);
  }

  resetSelector(valueInRange, elementTarget, difference) {
    for (let i = 1; valueInRange > elementTarget.length - 1; i++) {
      const option = document.createElement('option');
      option.text = option.value = (valueInRange - difference + i);
      elementTarget.appendChild(option);
    }
  }

  resetMonths() {
    while (this.dobMonthTarget.length !== 1) {
      this.dobMonthTarget.removeChild(this.dobMonthTarget.lastChild);
    }
    this.resetSelector(this.monthsInYear, this.dobMonthTarget, this.monthsInYear - (this.dobMonthTarget.length - 1));
  }

  resetDays() {
    this.resetSelector(this.daysInMonth, this.dobDayTarget, this.daysInMonth - (this.dobDayTarget.length - 1));
  }

  resetOptions() {
    this.resetMonths();
    if (this.daysInMonth < this.dobDayTarget.value) {
      this.dobDayTarget.value = '';
      this.dobDayTarget.parentElement.parentElement.classList.remove('valid');
      this.dobDayTarget.parentElement.parentElement.classList.remove('focused');
      this.validate(this.dobDayTarget);
    }
    while ((this.daysInMonth < this.dobDayTarget.length - 1) && this.dobYearTarget.value) {
      this.dobDayTarget.remove(this.dobDayTarget.length - 1);
    }
    this.resetDays();
  }

  validate(element) {
    element = element instanceof Event ? element.target : element;
    if (InputValidator.validateRequired(element)) {
      InputValidator.validatePattern(element)
    }
  }

  isLeapYear(year) {
    if (!year) {
      return false
    }
    return ((year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0))
  }

  getDaysInMonth(month, year) {
    if (this.preventFuture && year === this.today.getFullYear() && month === (this.today.getMonth() + 1)) {
      return this.today.getDate();
    }
    switch (month) {
      case 9:
      case 4:
      case 6:
      case 11:
        return 30;
      case 2:
        return this.isLeapYear(year) ? 29 : 28;
      default:
        return 31;
    }
  }

  getMonthsInYear(year) {
    if (this.preventFuture && year === this.today.getFullYear()) {
      return this.today.getMonth() + 1;
    }
    return 12;
  }
}
