import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'submit',
  ];

  handleButton(e) {
    this.submitTarget.classList.add('disabled');
    this.submitTarget.setAttribute('disabled', 'true');
    window.location = e.detail[0].redirect_path;
  }
}
