import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'programmesSection',
    'programmesTemplate',
  ]

  get type() {
    return this.data.element.dataset.type;
  }

  get newProgramme() {
    return this.programmesTemplateTarget.innerHTML.replace(/TEMPLATE_NUM/g, this.numProgrammes);
  }

  get numProgrammes() {
    return this.programmesSectionTarget.children.length;
  }

  addAdditionalProgramme() {
    this.programmesSectionTarget.insertAdjacentHTML('beforeend', this.newProgramme);
  }
}
