import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'letterGroup',
    'quickLinks',
    'data',
  ];

  static outlets = [
    'turbo-active-link',
    'search-form',
  ]

  clearNavigation() {
    this.turboActiveLinkOutlet.clearActiveLinks();
    this.searchFormOutlet.clearInput();
  }

  connect() {
    this.overflowContent();
  }

  overflowContent() {
    this.dataTarget.classList.toggle('glossary-quick-links__data--hidden');
    const data = this.getData();
    if (window.innerWidth >= 1024) {
      this.evenColumns(data, 4);
    } else if (window.innerWidth >= 374) {
      this.evenColumns(data, 3);
    } else {
      this.evenColumns(data, 1);
    }
  }

  getData() {
    const data = [];
    this.letterGroupTargets.forEach((item) => {
      const heading = item.getElementsByClassName('glossary-quick-links__letter_group--heading');
      const items = item.getElementsByClassName('glossary-quick-links__letter_group--item');

      data.push({ heading, items});
    });
    return data;
  }

  evenColumns(items, columnCount) {
    const colHeights = [];
    this.colMapping = [0];
    this.validItems = items.filter((x) => x.items.length > 0);
    const elements = [Array(this.validItems.length)];
    let avgItemHeight = 0;
    let avgColHeight = 0;
    let itemCount = 0;
    let newElem = null;

    for (let index = 0; index < this.validItems.length; index++) {
      elements[index] = this.dataTarget.children[index].cloneNode(true);
    }

    this.quickLinksTarget.innerHTML = '';
    for (let i = 0; i < columnCount; i++) {
      newElem = document.createElement('div');
      newElem.className = 'glossary-quick-links__col';
      this.quickLinksTarget.appendChild(newElem);
    }

    for (let index = 1; index < columnCount; index++) {
      this.colMapping.push(Math.round(this.validItems.length / columnCount * index));
    }

    for (let i = 0; i < columnCount; i++) {
      colHeights.push(0);
      itemCount = this.getItemCount(i);
      for (let q = this.colMapping[i]; q <= itemCount; q++) {
        this.quickLinksTarget.children[i].appendChild(elements[q]);
        colHeights[i] += elements[q].offsetHeight;
        this.quickLinksTarget.children[i].removeChild(elements[q]);
      }
    }

    avgItemHeight = colHeights.reduce((total, value) => total + value) / this.validItems.length;
    avgColHeight = avgItemHeight * this.validItems.length / columnCount;
    for (let index = 0; index < columnCount - 1; index++) {
      if (colHeights[index] > avgColHeight + avgItemHeight) {
        this.colMapping[index + 1]--;
      }
    }

    for (let i = 0; i < this.colMapping.length; i++) {
      itemCount = this.getItemCount(i);
      for (let q = this.colMapping[i]; q <= itemCount; q++) {
        this.quickLinksTarget.children[i].appendChild(elements[q]);
      }
    }
  }

  getItemCount(i) {
    if (i < this.colMapping.length - 1) {
      return this.colMapping[i + 1] - 1;
    }
    return this.validItems.length - 1;
  }
}
