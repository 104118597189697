/* eslint-disable no-unused-expressions */
import { Controller } from 'stimulus';
import swal from 'sweetalert';

export default class extends Controller {
  connect() {
    const form = document.querySelector('.form form');

    form.addEventListener('change', () => {
      this.constructor.isIE11() ? this.handleIE11() : this.handleModern();
    });
  }

  handleIE11() {
    this.constructor.disableTurbolinksIE11();
    window.onbeforeunload = () => 'You have not saved your details!\nAre you sure you wish to navigate away?';
  }

  handleModern() {
    let redirectURL;
    const controller = this;

    document.addEventListener('click', (e) => {
      redirectURL = e.target.href;
    });

    ['turbo:before-visit', 'page:before-change'].forEach((item) => {
      document.addEventListener(item, (event) => {
        controller.constructor.addWarningModal(event, redirectURL);
      });
    });
  }

  static async addWarningModal(event, redirectURL) {
    event.preventDefault();
    const successNotice = document.querySelector('.notice');

    if (!successNotice) {
      const isConfirm = await swal({
        title: 'You have not saved your details!',
        icon: 'warning',
        closeOnClickOutside: false,
        buttons: ['Continue without saving', 'Save'],
      });
      if (!isConfirm) window.location.href = redirectURL;
    } else {
      window.location.href = redirectURL;
    }
  }

  static disableTurbolinksIE11() {
    document.querySelectorAll('a').forEach((link) => {
      link.setAttribute('data-turbo', 'false');
    });
  }

  static isIE11() {
    return document.documentElement.classList.contains('b-ie--11');
  }
}
