import { Controller } from 'stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = [
    'scrollerLeftButton',
    'scrollerRightButton',
    'mainScroller',
  ];

  connect() {
    this.mySwiper = new Swiper(this.mainScrollerTarget, {
      freeMode: true,
      freeModeMomentum: true,
      freeModeSticky: true,
      resistanceRatio: 0.075,
      slidesPerView: 'auto',
      spaceBetween: 10,
      speed: 500,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      observer: true,
      observeParents: true,
    });

    this.scrollerButtonClasses();
  }

  scrollerButtonClasses() {
    this.buttonDisabling('isBeginning', this.scrollerLeftButtonTarget);
    this.buttonDisabling('isEnd', this.scrollerRightButtonTarget);
    const scroller = this;

    this.mySwiper.on('reachBeginning', () => scroller.scrollerLeftButtonTarget.classList.add('disabled'));

    this.mySwiper.on('reachEnd', () => scroller.scrollerRightButtonTarget.classList.add('disabled'));

    this.mySwiper.on('fromEdge', () => {
      scroller.scrollerLeftButtonTarget.classList.remove('disabled');
      scroller.scrollerRightButtonTarget.classList.remove('disabled');
    });
  }

  buttonDisabling(position, target) {
    if (this.mySwiper[position]) {
      target.classList.add('disabled');
    } else {
      target.classList.remove('disabled');
    }
  }

  scrollToLeft() {
    this.mySwiper.update();
    this.mySwiper.slidePrev();
  }

  scrollToRight() {
    this.mySwiper.update();
    this.mySwiper.slideNext();
  }

  update() {
    this.mySwiper.update();
    this.buttonDisabling('isBeginning', this.scrollerLeftButtonTarget);
    this.buttonDisabling('isEnd', this.scrollerRightButtonTarget);
  }
}
