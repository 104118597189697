import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { questionId: String }

  connect() {
    $('#info-tabs').on('deeplink.zf.tabs', function() {
      if (this.questionIdValue) {
        $('body, html').animate({
          scrollTop: $(`#${this.questionIdValue}`).offset().top - 80
        }, 1000);
      }
    });
  }
}