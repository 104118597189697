import { Controller } from 'stimulus';

const ACTIVE_BUTTON_CLASS = 'fee-reminder__carousel-buttons--active';

export default class extends Controller {
  static targets = ['reminder', 'button']

  connect() {
    this.xDown = null;
    this.yDown = null;
    this.swipeInterval = setInterval(() => this.swipeRight(), 5000);
    this.currentReminder = 0;
  }

  disconnect() {
    clearInterval(this.swipeInterval);
  }

  touchStart(event) {
    let { clientX, clientY } = (event.touches || event.originalEvent.touches)[0];
    this.xDown = clientX;
    this.yDown = clientY;
  }

  touchMove(event) {
    if (!this.xDown || !this.yDown) return;

    var xUp = event.touches[0].clientX;
    var yUp = event.touches[0].clientY;

    var xDiff = this.xDown - xUp;
    var yDiff = this.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        this.swipeRight();
      } else {
        this.swipeLeft();
      }
    }
    this.xDown = null;
    this.yDown = null;
  }

  swipeRight() {
    if (this.currentReminder === this.reminderTargets.length - 1) {
      this._goToReminder(0);
    } else {
      this._goToReminder(this.currentReminder + 1)
    }
  }

  swipeLeft() {
    if (this.currentReminder === 0) {
      this._goToReminder(this.reminderTargets.length - 1);
    } else {
      this._goToReminder(this.currentReminder - 1);
    }
  }

  _goToReminder(index) {
    this.currentReminder = index;
    for (let button of this.buttonTargets) {
      if (index === parseInt(button.dataset.index, 10)) {
        button.classList.add(ACTIVE_BUTTON_CLASS);
      } else {
        button.classList.remove(ACTIVE_BUTTON_CLASS);
      }
    }
    for (let reminder of this.reminderTargets) {
      if (index === parseInt(reminder.dataset.index, 10)) {
        reminder.classList.remove('display-none');
      } else {
        reminder.classList.add('display-none');
      }
    }
  }

  select({ currentTarget: { dataset: { index } } }) {
    this._goToReminder(parseInt(index, 10));
  }
}