import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['data']

  connect() {
    this.addToCart = this.addToCart.bind(this);
    let registerButton = this.element.querySelector(".button.button__primary--regular");
    if(registerButton)
      registerButton.addEventListener('click', this.addToCart);
  }

  addToCart() {
    dataLayer.push({ ecommerce: null });
    dataLayer.push(JSON.parse(this.dataTarget.text));
  }
}