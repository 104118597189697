import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['link', 'tab']

  openTab({ currentTarget }) {
    for (let tab of this.tabTargets) {
      if (tab.id === currentTarget.dataset.tab) {
        tab.style.display = 'block';
      } else {
        tab.style.display = 'none';
      }
    }

    for (let link of this.linkTargets) {
      if (link === currentTarget) {
        link.classList.add('active');
      } else {
        link.classList.remove('active');
      }
    }
  }
}