import { Controller } from 'stimulus';

const SUB_KEYS = [
  'Status for CPD',
  'Pro Rata',
  'Shortfall Suspended',
  'Minimum Hours Required',
  'Minimum Hours Required (Opening Balance)',
  'Recorded Hours',
  'Returned Hours'
];

export default class extends Controller {
  static targets = [
    'hoursBreakdownRow',
    'yearSelector',
    'breakdownWrapper',
    'breakdownContent',
    'totalHoursOfHours',
    'totalHoursProgress',
    'categoryHoursProgress',
    'yearIncrementor',
    'yearDecrementor',
    'carouselDot',
    'categoryHoursOfHours',
    'totalHoursOfHours'
  ]

  static values = {
    hoursBreakdown: Object,
    yearData: Object,
    hoursByYear: Object 
  }

  get years() {
    return Object.keys(this.hoursByYearValue);
  }

  connect() {
    this.toClone = this.hoursBreakdownRowTarget;
    this.previousYearCheck(new Date().getFullYear() - 1);
    this.myHoursExpand(0);
    this.setYear(new Date().getFullYear());
  }

  setYear(year) {
    this.yearSelectorTarget.innerHTML = year;
    this.setValues(year);
    this.setTotalHours(year);
    this.setHoursCarouselDots(year);
    this.setHoursBreakdown(year);
    this.setShortfallHours(year)
  }

  setShortfallHours(year) {
    let shortfallHours = document.getElementById('shorfallRecordedHours');
    if(shortfallHours)
      shortfallHours.innerText = this.yearDataValue[year]['recorded_hours'];
  }

  setHoursBreakdownLength(length) {
    this.breakdownWrapperTarget.style['display'] = length > 0 ? 'flex' : 'none';

    if (this.hasBreakdownContentTarget) {
      while (this.breakdownContentTarget.firstChild) {
          this.breakdownContentTarget.removeChild(this.breakdownContentTarget.firstChild);
      }
      for (let i = this.breakdownContentTarget.childElementCount; i < length; i++) {
        this.breakdownContentTarget.appendChild(this.toClone.cloneNode(true));
      }
      if (length > 0) return true;
    }
    return false;
  }

  flexRotate(element, element1, element2) {
    if (element.style['display'] == '') {
      element.style['display'] = 'flex';
      if (element1) element1.style['display'] = 'flex';
    } else {
      element.style['display'] = '';
      if (element1) element1.style['display'] = '';
    }
    if (element2.style['transform'] == '') {
      element2.style['transform'] = 'rotate(180deg)';
    } else {
      element2.style['transform'] = '';
    }
  }

  setHoursBreakdownContent(values) {
    for (let i = 0; values[i]; i++) {
      let currentContainer = this.hoursBreakdownRowTargets[i];
      let breakdownDeleteButton = currentContainer.querySelector('.hours-breakdown__content-delete');
      if (values[i]['Editable'] == 0) {
        currentContainer.removeChild(breakdownDeleteButton);
      } else {
        breakdownDeleteButton.setAttribute('id', `breakdownDelete_${i}`);
        breakdownDeleteButton.setAttribute('data-controller', 'cpd-hours');
        let breakdownButton = breakdownDeleteButton.querySelector('.hours-breakdown__content-delete-button');
        breakdownButton.setAttribute('data-action', 'click->cpd-hours#deleteEvent');
        breakdownButton.setAttribute('data-url', `/api/v1/cpd/${document.URL.match('users\/cpd\/(.*).lxp=true')[1]}/${values[i]['Accredited Event Id']}`);
      }
      currentContainer.getElementsByClassName('hours-breakdown__content-row-date')[0].innerHTML = values[i]['Date'];
      currentContainer.getElementsByClassName('hours-breakdown__content-row-name')[0].innerHTML = values[i]['Name'];
      currentContainer.querySelector('#breakdownRefNo').innerHTML = values[i]['Reference No'];
      currentContainer.querySelector('#breakdownType').innerHTML = values[i]['Type'];
      currentContainer.querySelector('#breakdownProvider').innerHTML = values[i]['Provider'];
      currentContainer.querySelector('#breakdownTotalHours').innerHTML = values[i]['Hours'] || values[i]?.["Categories"]?.['Total Hours'];
      let hoursCategories = currentContainer.querySelector('.cpdHoursBreakdownHoursContainer');
      delete values[i]?.['Categories']?.['Total Hours'];
      let hoursKeys = Object.keys(values[i]["Categories"] ? values[i]["Categories"] : {});
      if (hoursKeys.length > 0) {
        for (let a = 1; hoursKeys[a]; a++) {
          hoursCategories.appendChild(hoursCategories.firstElementChild.cloneNode('deep'));
        }
        let hoursBreakdownRows = hoursCategories.children;
        for (let a = 0; hoursBreakdownRows[a]; a++) {
          if (hoursKeys[a]) {
            hoursBreakdownRows[a].firstElementChild.innerHTML = `${hoursKeys[a]}:`;
            hoursBreakdownRows[a].lastElementChild.innerHTML = values[i]["Categories"][hoursKeys[a]];
          }
        }
      }
      else {
        hoursCategories.remove();
      }
      currentContainer.querySelector('.hours-breakdown__content-dropdown').setAttribute('id', `breakdown_${i}`);
      currentContainer.querySelector('.hours-breakdown__content-row-dropdown').setAttribute('id', `breakdownDropdown_${i}`);
      currentContainer.addEventListener('click', () => {
        this.flexRotate(document.getElementById(`breakdown_${i}`),
                        document.getElementById(`breakdownDelete_${i}`),
                        document.getElementById(`breakdownDropdown_${i}`));
      });
    }
  }

  setHoursBreakdown(year) {
    if (this.setHoursBreakdownLength(this.hoursBreakdownValue[year].length)) {
      this.setHoursBreakdownContent(this.hoursBreakdownValue[year]);
      this.flexRotate(document.getElementById(`breakdown_0`),
                      document.getElementById(`breakdownDelete_0`),
                      document.getElementById(`breakdownDropdown_0`));
      this.myHoursExpand(0);
      this.breakdownContentTarget.lastElementChild.style['border-bottom'] = 'none';
    }
  }

  setValues(year) {
    let dropdownElements = [];
    let categoryHoursTotal = 0;
    let categoryHoursCompleted = 0;
    let categoryHoursCheck = 0;
    let cpdKeys = Object.keys(this.hoursByYearValue[year]);
    for (let i = 0; cpdKeys[i]; i++) {
      dropdownElements[i] = document.getElementById('myHoursDropdownContainer_' + i);
    }
    for (let i = 0; dropdownElements[i]; i++) {
      for (let a = 0; dropdownElements[i].children[a]; a++) {
        dropdownElements[i].children[a].lastElementChild.innerHTML = this.hoursByYearValue[year][cpdKeys[i]][SUB_KEYS[a]];
        if (SUB_KEYS[a] == 'Minimum Hours Required') {
          categoryHoursTotal = categoryHoursTotal + this.hoursByYearValue[year][cpdKeys[i]][SUB_KEYS[a]];
          categoryHoursCheck = this.hoursByYearValue[year][cpdKeys[i]][SUB_KEYS[a]];
          document.getElementById('cpdRequiredHours_' + i).innerHTML = categoryHoursCheck;
        }
        if (SUB_KEYS[a] == 'Recorded Hours' && this.hoursByYearValue[year][cpdKeys[i]][SUB_KEYS[a]] >= categoryHoursCheck) {
          categoryHoursCompleted = categoryHoursCompleted + categoryHoursCheck;
          let statusDiv = document.getElementById('cpdStatus_' + i);
          statusDiv.className = 'cpd-my-hours__requirements-body-content-middle-right-tick';
          statusDiv.innerHTML = '';
          statusDiv.parentElement.style['gap'] = '145px';
          statusDiv.parentElement.style['left'] = '0px';
        } else if (SUB_KEYS[a] == 'Recorded Hours') {
          categoryHoursCompleted = categoryHoursCompleted + this.hoursByYearValue[year][cpdKeys[i]][SUB_KEYS[a]];
          let statusDiv = document.getElementById('cpdStatus_' + i);
          statusDiv.className = 'cpd-my-hours__requirements-body-content-middle-right';
          statusDiv.innerHTML = 'Incomplete';
          statusDiv.parentElement.style['gap'] = '120px';
          statusDiv.parentElement.style['left'] = '10px';
        }
      }
    }
    this.setCategoryHours(categoryHoursCompleted, categoryHoursTotal);
  }

  almostFullBar(width, barElement) {
    if (width > 95) {
      barElement.style['width'] = `${width}%`;
      barElement.style['border-radius'] = '40px';
    }
    else {
      barElement.style['width'] = `${width}%`;
      barElement.style['border-radius'] = '40px 0px 0px 40px';
    }
  }

  fullBar(barTextElement, barElement, formerValue, latterValue, endText) {
    barTextElement.innerHTML = `${formerValue} OF ${latterValue} ${endText} HOURS COMPLETE`;
    barElement.style['border-radius'] = '40px';
    barElement.style['width'] = '100%';
  }

  setTotalHours(year) {
    let totalHours = this.yearDataValue[year]['minimum_hours_required'];
    let remainingHours = this.yearDataValue[year]['remaining_hours'];
    let recordedHours = this.yearDataValue[year]['recorded_hours'];
    let barWidth = (totalHours - remainingHours) / parseFloat(totalHours) * 100;
    if (remainingHours > 0) {
      this.totalHoursOfHoursTarget.innerHTML = `${totalHours - remainingHours} OF ${totalHours} TOTAL HOURS COMPLETE`;
      this.almostFullBar(barWidth, this.totalHoursProgressTarget);
    } else
      this.fullBar(this.totalHoursOfHoursTarget, this.totalHoursProgressTarget, recordedHours, totalHours, 'TOTAL');
  }

  setCategoryHours(completed, total) {
    let totalWidth = completed / parseFloat(total) * 100;
    if (completed < total) {
      this.categoryHoursOfHoursTarget.innerHTML = `${completed} OF ${total} CATEGORY HOURS COMPLETE`;
      this.almostFullBar(totalWidth, this.categoryHoursProgressTarget);
    } else
      this.fullBar(this.categoryHoursOfHoursTarget, this.categoryHoursProgressTarget, total, total, 'CATEGORY');
  }

  adjustYear(e){
    let year = parseInt(this.yearSelectorTarget.innerHTML) + e.params.value;
    let otherButton = e.target.nextElementSibling?.nextElementSibling || e.target.previousElementSibling?.previousElementSibling 
    if (this.years.includes(`${year}`)) {
      this.setYear(year);
      otherButton.style['display'] = 'block';
      if (this.years.includes(`${year + e.params.value}`) == false)
        e.target.style['display'] = 'none';
    }
  }

  setHoursCarouselDots(year) {
    for (let dot of this.carouselDotTargets) {
      dot.style['opacity'] = '0.25';
    }
    document.getElementById('myHoursCarouselButton_' + year.toString()).style['opacity'] = '1';
  }

  expand({ currentTarget: { dataset: { index } } }) {
    this.myHoursExpand(index);
  }

  myHoursExpand(input) {
    let dropdownContainer = document.getElementById(`myHoursDropdownContainer_${input}`);
    let dropdownSelector = document.getElementById(`myHoursDropdown_${input}`);
  
    if (dropdownSelector.style.transform == 'rotate(180deg)') {
      dropdownSelector.style.transform = '';
    } else {
      dropdownSelector.style.transform = 'rotate(180deg)';
    }
  
    if (dropdownContainer.style.display == 'none' || dropdownContainer.style.display == '') {
      dropdownContainer.style.display = 'block';
    } else {
      dropdownContainer.style.display = 'none';
    }
  }

  previousYearCheck(year) {
    if(this.years.includes(`${year}`) == false) {
      this.yearDecrementorTarget.style['display'] = 'none';
    }
  } 
}