import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    window.location = '/users/sign_out';
  }

  logoutUrl(){
    const inProd = window.location.href.match('https://iob.ie/');

    const prodLogout    = 'https://lms.iob.ie/login/logoutazure.php';
    const stagingLogout = 'https://ioblearn-uat.iob.ie/login/logoutazure.php';

    return inProd ? prodLogout : stagingLogout
  }
}
