import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'root',
    'searchIcon',
    'input'
  ];

  connect() {
    this.inputTarget.value = '';

    document.addEventListener('click', (event) => {
      if (!this.rootTarget.contains(event.target)) {
        this.clearInput();
      }
    });

    document.addEventListener('keydown', (event) => {
      const key = event.keyCode || event.which;
      if (key === 27) {
        this.clearInput();
        input.value = '';
      }
    });
  }

  clearInput() {
    this.rootTarget.setAttribute('data-state', 'active');
    const input = this.rootTarget.querySelector('input.string');
  }

  activateSearch() {
    this.rootTarget.setAttribute('data-state', 'active');
    const input = this.rootTarget.querySelector('input.string');
    input.focus();
  }
}
