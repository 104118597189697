// Stylesheets
import './stylesheets/application.scss';

// Javascript

document.addEventListener('turbo:load', () => {
  $(document).foundation();
}, false);

function setup() {
  window.jQuery = $;
  window.$ = $;
}

function teardown() {
  document.documentElement.classList.remove('is-reveal-open');
  $('.reveal-overlay').hide();
}

const Application = {
  setup,
  teardown,
};

document.addEventListener('DOMContentLoaded', Application.setup);
document.addEventListener('turbo:load', Application.setup, {
  once: true,
});
document.addEventListener('turbo:render', Application.setup);
document.addEventListener('turbo:before-render', Application.teardown);
