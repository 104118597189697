import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['work', 'home', 'checkbox']

  employerChange({ currentTarget: { value } }) {
    if (value !== 'None') return;

    this.workTarget.checked = false;
    this.homeTarget.checked = true;
  }

  change({ currentTarget }) {
    for (let checkbox of this.checkboxTargets) {
      if (checkbox !== currentTarget) checkbox.checked = !currentTarget.checked;
    }
  }
}